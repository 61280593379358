/*!


*/
import React, { useState } from "react";
import * as d3 from "d3";
import axios, { post } from "axios";

import { useSelector, useDispatch } from "react-redux";

import Buttons from "components/Ponce/Buttons";
import ItemMasterTableView from "components/Ponce/ItemMaster/ItemMasterTableView";
import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import {
  Card,
  FormGroup,
  Input,
  Button,
  Badge,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  Container,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import { setButtons } from "redux/slices/ButtonsSlice";
import QRCode from "qrcode.react";
import { setMessage } from "redux/slices/MessageSlice";
import { setIndicator } from "redux/slices/IndicatorSlice";
import { setItemMasterItems } from "redux/slices/ItemMasterSlice";
import { useRouteMatch } from "react-router-dom";
import { updateExportFileMapping } from "redux/slices/ImportFilesSlice";

//core components

function OutputFileMapping({ showLog }) {
  const dispatch = useDispatch();
  let fileNameTemp = useRouteMatch("/admin/outputFile/:file").params.file;
  const tmp = fileNameTemp.split("|");
  let fileName = tmp[0];
  console.log();

  const { ip } = useSelector((state) => state.ip);
  //const jobs = [{ id: 0, name: "Job", owner: "Bob", type: "" }];

  const [outputColums, setOutputColumns] = useState([
    /* {
      id: "inv_auditor",
      file_name: "source_output.csv",
      title: "counter",
      sequence: 3,
      is_summed: 0,
      format: null,
      divide_by: null,
    },
    {
      file_name: "source_output.csv",
      id: "inv_quantity",
      title: "count",
      sequence: 4,
      is_summed: 1,
      format: null,
      divide_by: null,
    },
    {
      file_name: "source_output.csv",
      id: "inv_section",
      title: "section",
      sequence: 2,
      is_summed: 0,
      format: null,
      divide_by: null,
    },
    {
      file_name: "source_output.csv",
      id: "item_id",
      title: "item",
      sequence: 1,
      is_summed: 0,
      format: null,
      divide_by: null,
    }, */
  ]);
  /*
  file_name	"source_output.csv"
id	"inv_auditor"
title	"counter"
sequence	3
is_summed	0
format	null
divide_by	null
  */

  const outputFields = [
    {
      dataField: "file_name",
      text: "File",
      sort: true,
      headerAlign: "left",
      align: "left",
      editable: false,
      csvType: "number",
    },
    {
      dataField: "id",
      text: "ID",
      sort: true,
      headerAlign: "left",
      align: "left",
      editable: false,
      csvType: "number",
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
      headerAlign: "left",
      align: "left",
      editable: false,
      csvType: "number",
    },
    {
      dataField: "sequence",
      text: "Sequence",
      sort: true,
      headerAlign: "right",
      align: "right",
      editable: false,
      csvType: "number",
    },
    {
      dataField: "is_summed",
      text: "Summarized",
      sort: true,
      headerAlign: "left",
      align: "left",
      editable: false,
      csvType: "number",
    },
    {
      dataField: "format",
      text: "Format",
      sort: true,
      headerAlign: "left",
      align: "left",
      editable: false,
      csvType: "number",
    },

    {
      dataField: "divide_by",
      text: "Divided by",
      sort: true,
      headerAlign: "left",
      align: "left",
      editable: false,
      csvType: "number",
    },
  ];

  const [tableColumns, setTableColumns] = useState([
    {
      sequence: -1,
      cid: 0,
      name: "id",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 1,
      name: "inv_sequence",
      type: "INTEGER",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 2,
      name: "item_id",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 3,
      name: "inv_quantity",
      type: "double",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "quantity",
    },
    {
      sequence: -1,
      cid: 4,
      name: "inv_area",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 5,
      name: "inv_section",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 6,
      name: "inv_auditor",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 7,
      name: "inv_file_name",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 8,
      name: "is_in_item_master",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 9,
      name: "was_it_found",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 10,
      name: "was_it_updated",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 11,
      name: "original_quantity",
      type: "double",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 12,
      name: "original_item",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 13,
      name: "base_item_id",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 14,
      name: "entry_date",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 15,
      name: "entry_time",
      type: "double",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 16,
      name: "check_digit",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 17,
      name: "formatted_date",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 18,
      name: "item_description",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 19,
      name: "item_price",
      type: "",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 20,
      name: "department",
      type: "",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 21,
      name: "department_description",
      type: "",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 22,
      name: "sku",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 23,
      name: "quantity_on_hand",
      type: "double",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 24,
      name: "item_cost",
      type: "",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 25,
      name: "single_count",
      type: "INTEGER",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 26,
      name: "item_master_check_digit",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 27,
      name: "original_item_value",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 28,
      name: "other_info",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 29,
      name: "extra",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 30,
      name: "dept_description",
      type: "TEXT",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 31,
      name: "percentage",
      type: "double",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
    {
      sequence: -1,
      cid: 32,
      name: "section_description",
      type: "",
      notnull: 0,
      dflt_value: null,
      pk: 0,
      is_selected: "N",
      is_summarized: "N",
      formatting: "",
      title: "",
    },
  ]);
  /* const columns = [
    {
      dataField: "id",
      text: "Job ID",
    },
    {
      dataField: "name",
      text: "Job Name",
    },
    {
      dataField: "owner",
      text: "Job Owner",
    },
    {
      dataField: "type",
      text: "Job Type",
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: "A",
            label: "A",
          },
          {
            value: "B",
            label: "B",
          },
          {
            value: "C",
            label: "C",
          },
          {
            value: "D",
            label: "D",
          },
          {
            value: "E",
            label: "E",
          },
        ],
      },
    },
  ]; */

  const tableFields = [
    {
      dataField: "cid",
      text: "row",
      sort: true,
      headerAlign: "left",
      align: "left",
      editable: false,
      csvType: "number",
    },
    {
      dataField: "name",
      text: "Field",
      sort: true,
      headerAlign: "left",
      editable: false,
      align: "left",
      csvType: "number",
    },

    {
      dataField: "is_selected",
      text: "Selected?",
      sort: true,
      editor: {
        type: Type.CHECKBOX,
        value: "Y:N",
      },
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
      headerAlign: "left",
      align: "left",
      editable: editableQuantity,
      csvType: "number",
    },
    {
      dataField: "sequence",
      text: "Sequence",
      sort: true,
      headerAlign: "right",
      align: "right",
      csvType: "number",
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: "-1",
            label: "Select one",
          },
          {
            value: "1",
            label: "1",
          },
          {
            value: "2",
            label: "2",
          },
          {
            value: "3",
            label: "3",
          },
          {
            value: "4",
            label: "4",
          },
          {
            value: "5",
            label: "5",
          },
          {
            value: "6",
            label: "6",
          },
          {
            value: "7",
            label: "7",
          },
          {
            value: "8",
            label: "8",
          },
          {
            value: "9",
            label: "9",
          },
          {
            value: "10",
            label: "10",
          },
          {
            value: "11",
            label: "11",
          },
          {
            value: "12",
            label: "12",
          },
          {
            value: "13",
            label: "13",
          },
          {
            value: "14",
            label: "14",
          },
          {
            value: "15",
            label: "15",
          },
          {
            value: "16",
            label: "16",
          },
          {
            value: "17",
            label: "17",
          },
          {
            value: "18",
            label: "18",
          },
          {
            value: "19",
            label: "19",
          },
          {
            value: "20",
            label: "20",
          },
          {
            value: "21",
            label: "21",
          },
          {
            value: "22",
            label: "22",
          },
          {
            value: "23",
            label: "23",
          },
          {
            value: "24",
            label: "24",
          },
          {
            value: "25",
            label: "25",
          },
          {
            value: "26",
            label: "26",
          },
          {
            value: "27",
            label: "27",
          },
          {
            value: "28",
            label: "28",
          },
          {
            value: "29",
            label: "29",
          },
          {
            value: "30",
            label: "30",
          },
          {
            value: "31",
            label: "31",
          },
          {
            value: "32",
            label: "32",
          },
          {
            value: "33",
            label: "33",
          },
        ],
      },
    },

    {
      dataField: "is_summarized",
      text: "Summarized?",
      sort: true,
      editable: editableSummarized,
      editor: {
        type: Type.CHECKBOX,
        value: "Y:N",
      },
      //editable: editableValue,
    },
    {
      dataField: "formatting",
      text: "Format",
      sort: true,
      headerAlign: "center",
      align: "center",
      csvType: "number",
      editable: editableFormatting,
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: "999",
            label: "Select one",
          },
          {
            value: "%m/%d/%Y",
            label: "%m/%d/%Y",
          },
          {
            value: "%m-%d-%Y",
            label: "%m-%d-%Y",
          },
          {
            value: "%Y-%m-%d",
            label: "%Y-%m-%d",
          },
          {
            value: "%Y-%m-%d %H:%M:%S",
            label: "%Y-%m-%d %H:%M:%S",
          },
          {
            value: "%Y/%m/%d %H:%M:%S",
            label: "%Y/%m/%d %H:%M:%S",
          },
        ],
      },
      //editable: false,
      //editable: editableFormatting,
      //formatter: formatFormatter,
      //formatExtraData: dateFormats,
    },
  ];

  console.log(tableColumns);

  React.useEffect(() => {
    getData();
  }, [ip]);

  function editableFormatting(content, row, rowIndex, columnIndex) {
    //console.log("row", row);
    let returnValue = true;
    if (row.name === "formatted_date") {
      returnValue = true;
    } else {
      returnValue = false;
    }

    return returnValue;
  }

  function editableQuantity(content, row, rowIndex, columnIndex) {
    //console.log("row", row);
    let returnValue = true;
    if (row.name !== "inv_quantity") {
      returnValue = true;
    } else {
      returnValue = false;
    }

    return returnValue;
  }

  function editableSummarized(content, row, rowIndex, columnIndex) {
    //console.log("row", row);
    let returnValue = true;
    if (row.name === "inv_quantity") {
      returnValue = true;
    } else {
      returnValue = false;
    }

    return returnValue;
  }

  function getData() {
    //console.log("get data", ip);
    dispatch(setIndicator(true));
    ip.length > 0 &&
      fileName.length > 0 &&
      axios
        .get(
          "http://" +
            ip[0].ip +
            ":" +
            ip[0].websocket_port +
            "/exportFileMapping/" +
            fileName
        )
        .then((response) => {
          dispatch(
            setMessage({
              message: "Fields mapping retrieved",
              variant: "info",
            })
          );

          console.log(response);
          response.data.data.forEach((d) => {
            //d.customerIDStore = "" + d.id + "-" + d.store_number;
            //d.key = "" + d.customer_id + d.store_number;
          });
          //console.log(response);
          if (response.data.data.length > 0) {
            setOutputColumns(response.data.data);
            const temp = [...tableColumns];
            response.data.data.forEach((d) => {
              temp.forEach((e) => {
                if (e.name === d.id) {
                  console.log(d, e);
                  e.is_selected = "Y";
                  e.title = d.title;
                  e.is_summarized = d.is_summed === 1 ? "Y" : "N";
                  e.sequence = d.sequence;
                  e.formatting = d.format;
                }
              });
            });
            setTableColumns(temp);

            //updateColumns(response.data.data[0]);
            //setFieldMapping(response.data.data);
            //setCustomers(response.data.data);
            //updateColumns(response.data.data[0]);
          }
          //setLocationsTable(response.data.data);
          //setLocationsTable(prevState => {
          //  return [...response.data.data]
          //
          //;
          //});
          dispatch(setIndicator(false));
          //callback(response.data);
        })
        .catch((error) => {
          dispatch(setIndicator(false));
          dispatch(setMessage({ message: error, variant: "danger" }));
          console.log(error);
          //console.log("*****  "+error)
        });
  }

  function handleClick(event) {
    console.log("handleClick");

    const tempColumns = [];
    const tempTableColumn = [...tableColumns];

    tableColumns.forEach((d) => {
      console.log(d);
      if (d.is_selected === "Y") {
        const newField = {
          file_name: fileName,
          id: d.name,
          title: d.title === "" || d.title === null ? d.name : d.title,
          sequence: d.sequence,
          is_summed: d.is_summarized === "Y" ? 1 : 0,
          format: d.formatting,
          divide_by: 1000,
        };
        tempColumns.push(newField);
      }

      setOutputColumns(tempColumns);
      setTableColumns(tempTableColumn);
    });
  }
  function handleClick2(event) {
    console.log("handleClick2");
    dispatch(
      updateExportFileMapping({
        action: "updateExportFileMapping",
        method: "post",
        url:
          "http://" +
          ip[0].ip +
          ":" +
          ip[0].websocket_port +
          "/updateExportFileMapping",
        data: outputColums,
      })
    );
  }

  return (
    <>
      <SimpleHeader name="" parentName="" />
      <Container className="mt--6" fluid>
        <Card>
          <BootstrapTable
            keyField="id"
            data={outputColums}
            columns={outputFields}
            cellEdit={cellEditFactory({
              mode: "click",
              blurToSave: true,
              beforeSaveCell(oldValue, newValue, row, column, done) {
                const newRow = {
                  ...row,
                  [column.dataField]: newValue,
                };

                console.log("newRow", newRow);
                //console.log(setupCustomerLocations)
                //let temp = [...setupCustomerLocations,newRow];
                //console.log(temp);
                //dispatch(setCustomer(newRow));

                //done(false); // The changes are handled by the dispatch

                //return { async: true };
              },
              afterSaveCell: (oldValue, newValue, row, column) => {
                console.log(tableColumns);
                console.log("After Saving Cell!!");
              },
            })}
          />

          <CardFooter>
            <Button
              disabled={outputColums.length < 1 ? true : false}
              color="info"
              outline
              block
              onClick={handleClick2}
            >
              Save Output fields
            </Button>
          </CardFooter>
        </Card>
        <Card>
          <BootstrapTable
            keyField="cid"
            data={tableColumns}
            columns={tableFields}
            cellEdit={cellEditFactory({
              mode: "click",
              blurToSave: true,
              beforeSaveCell(oldValue, newValue, row, column, done) {
                const newRow = {
                  ...row,
                  [column.dataField]: newValue,
                };

                //console.log("newRow", newRow);

                const temp = outputColums.filter((d) => {
                  return d.id === newRow.name;
                });

                //console.log(temp);

                const newField = {
                  file_name: "source_output.csv",
                  id: newRow.name,
                  title: newRow.name,
                  sequence: newRow.sequence,
                  is_summed: newRow.is_summarized === "Y" ? 1 : 0,
                  format: newRow.formatting,
                  divide_by: null,
                };
                console.log(newField);

                if (temp.length === 0) {
                  if (newRow.is_selected === "Y") {
                    //setOutputColumns((prevState) => {
                    //return [...prevState, newField];
                    //});
                  }
                } else {
                  console.log(temp.length);
                  temp[0].file_name = "source_output.csv";
                  temp[0].id = newRow.name;
                  temp[0].title = newRow.name;
                  temp[0].sequence = newRow.sequence;
                  temp[0].is_summed = newRow.is_summarized === "Y" ? 1 : 0;
                  temp[0].format = newRow.formatting;
                  temp[0].divide_by = null;
                  const temp1 = outputColums.filter((d) => {
                    return d.id !== newRow.name;
                  });
                  //setOutputColumns((prevState) => {
                  //return [...temp1, temp[0]];
                  //});
                }

                //console.log(setupCustomerLocations)
                //let temp = [...setupCustomerLocations,newRow];
                //console.log(temp);
                //dispatch(setCustomer(newRow));

                //done(false); // The changes are handled by the dispatch

                //return { async: true };
              },
              afterSaveCell: (oldValue, newValue, row, column) => {
                console.log("row", row);
                const temp = outputColums.filter((d) => {
                  return d.id === row.name;
                });

                console.log(temp);

                const newField = {
                  file_name: "source_output.csv",
                  id: row.name,
                  title: row.name,
                  sequence: row.sequence,
                  is_summed: row.is_summarized === "Y" ? 1 : 0,
                  format: row.formatting,
                  divide_by: null,
                };
                console.log(newField);

                if (temp.length === 0) {
                  if (row.is_selected === "Y") {
                    //setOutputColumns((prevState) => {
                    //return [newField];
                    //});
                  }
                } else {
                  console.log(temp.length);
                  temp[0].file_name = "source_output.csv";
                  temp[0].id = row.name;
                  temp[0].title = row.name;
                  temp[0].sequence = row.sequence;
                  temp[0].is_summed = row.is_summarized === "Y" ? 1 : 0;
                  temp[0].format = row.formatting;
                  temp[0].divide_by = null;
                  const temp1 = outputColums.filter((d) => {
                    return d.id !== row.name;
                  });
                }
                console.log("After Saving Cell!!");
              },
            })}
          />
          <CardFooter>
            <Button color="info" outline block onClick={handleClick}>
              Update Output Fields
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default OutputFileMapping;
