import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "reactstrap";

//import { createFile } from "redux/slices/RetailInventorySlice";

import {
  createFile,
  processInventory,
} from "redux/slices/RetailInventorySlice";

import {
  loadItemMasterItems,
  viewItemMasterItems,
} from "../../redux/slices/ItemMasterSlice";
import { setIndicator } from "redux/slices/IndicatorSlice";
import axios from "axios";
import { setMessage } from "redux/slices/MessageSlice";
import { getSettings } from "../../redux/slices/SettingsSlice";

function Buttons({ pageName }) {
  const dispatch = useDispatch();

  //const { buttons} = useSelector((state) => state.buttons);
  const { ip } = useSelector((state) => state.ip);
  const { settings } = useSelector((state) => state.settings);
  const { customers } = useSelector((state) => state.customers);
  //const {columns} = useSelector((state)=>state.columns);
  const [columns, setColumns] = useState([]);
  const [columnMappings, setColumnMappings] = useState([]);

  const [pageButtons, setPageButtons] = useState([]);

  console.log("buttons", settings, ip, pageButtons);

  React.useEffect(() => {
    ip.length === 1 &&
      dispatch(
        getSettings({
          action: "getSettings",
          method: "get",
          url: "http://" + ip[0].ip + ":" + ip[0].websocket_port + "/settings",
        })
      );
  }, [dispatch, ip, settings.length]);

  //console.log("settings", settings);

  //const protocol = window.location.protocol;
  //const domain = window.location.hostname;
  //const port = window.location.port;

  //const full = `${protocol}://${domain}:${port? port : ""}`

  //console.log(settings);

  //const pageButtons = buttons;
  /*
  
customer_type	button_label	button_type	
0	Create	create	
0	Load	load	
0	Process Inventory	process	
0	View	view	

  */

  //const pageButtons = buttons.filter(d=>{
  /* const temp = d.files.filter(f=>{
      return f.page === pageName;
    })
    console.log(temp);
    if (temp.length > 0) {
      return true;
    } else {
      return false;
    } */

  //return d.page === pageName
  //})

  function getButtonData() {
    //console.log("get data");
    dispatch(setIndicator(true));
    ip.length > 0 &&
      settings.length > 0 &&
      axios
        .get("http://" + ip[0].ip + ":" + ip[0].websocket_port + "/buttons", {
          params: {
            customer_type: settings[0].customer_type,
            page_name: pageName,
          },
        })
        .then((response) => {
          //console.log("response", response);
          dispatch(
            setMessage({
              message: "Button information has been retrieved",
              variant: "info",
            })
          );
          // response.data.data.forEach(d=>{
          //d.key = "" + d.customer_id + d.store_number;
          //})
          //if (response.data.data.length> 0) {
          //  updateColumns(response.data.data[0]);
          //}
          //setLocationsTable(response.data.data);
          //setLocationsTable(prevState => {
          //return [...response.data.data]

          //;
          //});
          //console.log(response.data.data);
          const tokenData = settings.length > 0 ? settings[0].tokenData : null;
          //console.log("tokenData", tokenData);
          setPageButtons(
            response.data.data.filter((d) => {
              if (
                d.email === "gilberto@poncesolutions.com" ||
                (tokenData !== null && tokenData.email === d.email)
              ) {
                return true;
              } else {
                return false;
              }
            })
          );
          dispatch(setIndicator(false));
          //callback(response.data);
        })
        .catch((error) => {
          dispatch(setIndicator(false));
          dispatch(setMessage({ message: error, variant: "danger" }));
          console.log(error);
          //console.log("*****  "+error)
        });
  }

  function getColumnData() {
    //console.log("get data");
    dispatch(setIndicator(true));
    ip.length > 0 &&
      axios
        .get("http://" + ip[0].ip + ":" + ip[0].websocket_port + "/column")
        .then((response) => {
          //console.log(response);
          dispatch(
            setMessage({
              message: "Column information has been retrieved",
              variant: "info",
            })
          );
          //response.data.data.forEach(d=>{
          //d.key = "" + d.customer_id + d.store_number;
          //})
          //if (response.data.data.length> 0) {
          //  updateColumns(response.data.data[0]);
          //}
          //setLocationsTable(response.data.data);
          //setLocationsTable(prevState => {
          //return [...response.data.data]

          //;
          //});
          //setPageButtons(response.data.data);

          setColumns(response.data.data);
          dispatch(setIndicator(false));
          //callback(response.data);
        })
        .catch((error) => {
          dispatch(setIndicator(false));
          dispatch(setMessage({ message: error, variant: "danger" }));
          console.log(error);
          //console.log("*****  "+error)
        });
  }

  function handleClick(event) {
    const tmp = event.target.id.split("-");

    //console.log(tmp);
    switch (tmp[1]) {
      case "convert":
        break;
      case "create":
        //console.log("create", tmp[3]);
        const fileExtension01 = tmp[3].split(".")[1].toUpperCase();
        let currentCustomer = null;
        let currentColumns = columns.filter(
          (d) => d.file_type === tmp[2] && d.is_retrieved === 1
        );
        //console.log(currentColumns);
        let currentCustomerType = 0;
        if (settings.length > 0) {
          currentCustomer = customers.filter(
            (d) => d.id === settings[0].current_customer_id
          );
          currentCustomerType =
            currentCustomer.length === 1 ? currentCustomer[0].customer_type : 0;
        }
        currentColumns.sort(
          (a, b) => parseFloat(a.sequence) - parseFloat(b.sequence)
        );
        //console.log(currentCustomer, tmp[3], currentColumns);
        //INVENTORY_DETAILED.CSV
        //INVENTORY_SUMMARIZED.CSV
        ip.length === 1 &&
          settings.length === 1 &&
          dispatch(
            createFile({
              action: "createFile",
              method: "post",
              url:
                "http://" +
                ip[0].ip +
                ":" +
                ip[0].websocket_port +
                "/createFile",
              customerID: settings[0].current_customer_id,
              columns:
                tmp[3] === "INVENTORY_DETAILED.CSV" ||
                tmp[3] === "INVENTORY_SUMMARIZED.CSV"
                  ? currentColumns
                  : setOutputColumns(tmp[3]),
              page: pageName,
              //MYSQL
              //dateFormat: "%m-%d-%Y %H:%i:%s",
              //SQLITE
              dateFormat: "%m-%d-%Y %H:%M:%S",
              fileExtension: fileExtension01,
              fileName: tmp[3],
            })
          );
        break;
      case "load":
      case "Load":
        console.log("load", tmp);
        setMappings(tmp[3]);
        const fileExtension = tmp[3].split(".")[1].toUpperCase();
        dispatch(
          loadItemMasterItems({
            action: "loadFile",
            method: "get",
            url:
              tmp[3] === "ITEM_MASTER.CSV"
                ? "http://" +
                  ip[0].ip +
                  ":" +
                  ip[0].websocket_port +
                  "/loadItemMaster/" +
                  tmp[3]
                : "http://" +
                  ip[0].ip +
                  ":" +
                  ip[0].websocket_port +
                  "/loadCustomFile/" +
                  tmp[3],
            customerID: settings.length > 0 ? settings[0].customer_id : 0,
            customerType: settings.length > 0 ? settings[0].customer_type : 0,
            page: pageName,
            fileType: tmp[2],
            fileName: tmp[3],
            fileExtension: fileExtension,
            columnMappings: setMappings(tmp[3]),
          })
        );
        break;
      case "process":
        ip.length === 1 &&
          dispatch(
            processInventory({
              action: "processInventory",
              method: "get",
              url:
                "http://" +
                ip[0].ip +
                ":" +
                ip[0].websocket_port +
                "/processInventory",
              customerID: 0,
              page: pageName,
            })
          );
        break;
      case "view":
        /* dispatch(
          viewInventoryOutput({
            action: "viewFile",
            customerID: 0,
            page:pageName,
            fileName:  tmp[3],
          })
        ); */
        ip.length === 1 &&
          dispatch(
            viewItemMasterItems({
              action: "viewFile",
              url: "http://" + ip[0].ip + ":" + ip[0].websocket_port,
              customerID: 0,
              page: pageName,
              fileType: tmp[2],
              fileName: tmp[3],
              columns:
                tmp[3] === "INVENTORY_DETAILED.CSV" ||
                tmp[3] === "INVENTORY_SUMMARIZED.CSV"
                  ? currentColumns
                  : JSON.parse(setOutputColumns(tmp[3])),
            })
          );
        break;

      default:
        break;
    }
  }

  function setOutputColumns(fileName) {
    const tempColumns = [];
    //console.log("setOutputColumns", fileName);
    settings.length > 0 &&
      settings[0].output_columns.forEach((d) => {
        //const col = { id: "item_id", title: "item_id" };
        if (d.file_name === fileName) {
          //console.log("d", d);
          tempColumns.push({
            id: d.id,
            title: d.title,
            sequence: d.sequence,
            is_summed: d.is_summed,
            is_removing_check_digit: d.is_removing_check_digit,
            format: d.format,
            pad_zeros_to_length: d.pad_zeros_to_length,
            divide_by: d.divide_by,
          });
        }
      });
    //setColumnMappings(tempMapping);
    //console.log("tempColumns", tempColumns);
    return JSON.stringify(tempColumns);
  }

  function setMappings(fileName) {
    const tempMapping = [];
    //console.log("setMappings", fileName);
    settings.length > 0 &&
      settings[0].accepted_files.forEach((d) => {
        //console.log(d);
        if (d.file_name === fileName) {
          tempMapping.push(d);
        }
      });
    //setColumnMappings(tempMapping);
    //console.log(columnMappings);
    return JSON.stringify(tempMapping);
  }

  React.useEffect(() => {
    getButtonData();
  }, [ip, settings]);

  React.useEffect(() => {
    getColumnData();
  }, [ip, settings]);

  return (
    <Row>
      {pageButtons.map((d) => {
        //console.log(d);

        if (
          d.button_label !== "Process Inventory" &&
          d.button_label !== "Load"
        ) {
          if (d.button_type === "create") {
            return (
              <Col
                key={
                  "button" +
                  d.button_type +
                  "-" +
                  d.file_type +
                  "-" +
                  d.file_name
                }
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="mx-auto"
              >
                <Row>
                  <Col sm={10}>
                    <Button
                      key={d.button_type}
                      className="btn-block d-flex justify-content-between align-items-center   mt-1"
                      color="info"
                      outline
                      id={
                        "btn-" +
                        d.button_type +
                        "-" +
                        d.file_type +
                        "-" +
                        d.file_name +
                        "-" +
                        d.button_label
                      }
                      onClick={handleClick}
                      block
                    >
                      {d.file_name !== ""
                        ? d.button_label + " " + d.file_name
                        : d.button_label}
                    </Button>
                  </Col>
                  {d.file_name !== "ECIS.TXT" && (
                    <Col sm={2}>
                      <Button
                        className="btn-icon btn-2"
                        href={"/data/" + d.file_name}
                        color="success"
                        type="button"
                        title={"Download " + d.file_name}
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-arrow-down" />
                        </span>
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            );
          } else {
            return (
              <Col
                key={
                  "button" +
                  d.button_type +
                  "-" +
                  d.file_type +
                  "-" +
                  d.file_name
                }
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="mx-auto"
              >
                <Button
                  key={d.button_type}
                  className="btn-block d-flex justify-content-between align-items-center   mt-1"
                  color="info"
                  outline
                  id={
                    "btn-" +
                    d.button_type +
                    "-" +
                    d.file_type +
                    "-" +
                    d.file_name +
                    "-" +
                    d.button_label
                  }
                  onClick={handleClick}
                  block
                >
                  {d.file_name !== ""
                    ? d.button_label + " " + d.file_name
                    : d.button_label}
                </Button>
              </Col>
            );
          }
        } else {
          return (
            <Col
              key={
                "button" + d.button_type + "-" + d.file_type + "-" + d.file_name
              }
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="mx-auto"
            >
              <Button
                key={d.button_type}
                className="btn-block d-flex justify-content-between align-items-center   mt-1"
                color="info"
                outline
                id={
                  "btn-" +
                  d.button_type +
                  "-" +
                  d.file_type +
                  "-" +
                  d.file_name +
                  "-" +
                  d.button_label
                }
                onClick={handleClick}
                block
              >
                {d.file_name !== ""
                  ? d.button_label + " " + d.file_name
                  : d.button_label}
              </Button>
            </Col>
          );
        }
      })}
    </Row>
  );
}

export default Buttons;
