import React, { useState } from "react";

// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts

import { useSelector } from "react-redux";
// reactstrap components
import { Card, Container, Row } from "reactstrap";


const { SearchBar } = Search;

function InventorySummary() {

  const fileName="inventory_summary.csv"

  const { dashboardInventorySummary } = useSelector(
    (state) => state.dashboardInfo
  );

  const [tableColumns, setTableColumns] = useState([
    {
      dataField: "inv_auditor",
      text: "Auditor",
      sort: true,
      hidden: false,
      csvType: "number",
    },
    {
      dataField: "entries",
      text: "Entries",
      sort: true,
      hidden: true,
      csvType: "number",
    },
  ]);

  function isRegProperty(obj) {
    var t = typeof obj;
    return t;
  }

  React.useEffect(() => {
    if (dashboardInventorySummary.length > 0) {
      const cols = [];
      const keys = Object.keys(dashboardInventorySummary[0]);

      for (let i = 0; i < keys.length; i++) {
        const a =
          isRegProperty(dashboardInventorySummary[0][keys[i]]) === "number"
            ? "right"
            : "left";
        let isDummy;
       
        isDummy =
          keys[i].includes("entry_time") || keys[i].includes("key")
            ? true
            : false;

        const c = {
          dataField: keys[i],
          text: keys[i],
          sort: true,
          headerAlign: a,
          isDummyField: isDummy,
          hidden: isDummy,
          align: a,
          csvType: "number",
        };

        cols.push(c);
      }
      setTableColumns(cols);

    }
  }, [dashboardInventorySummary]);

  const { ExportCSVButton } = CSVExport;

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col">
            <Card >
              {dashboardInventorySummary.length > 0 && (
                <ToolkitProvider
                  data={dashboardInventorySummary}
                  keyField="key"
                  columns={tableColumns}
                  search
                  exportCSV={{
                    fileName: fileName,
                    separator: ",",
                    blobType: "text/csv;charset=ansi",
                  }}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 pb-1"
                        >
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                          <br />

                          <ExportCSVButton {...props.csvProps}>
                            Export CSV {"(" + fileName + ")"} 
                          </ExportCSVButton>
                        </div>
                      }

                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        striped
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default InventorySummary;
