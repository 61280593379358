/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 
*/

import Dashboard from "views/pages/dashboards/DashboardPonce.js";

// Customs

const routes = [
  {
    collapse: true,
    name: "Dashboards",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/payment",
        name: "Payment",
        miniName: "D",
        email: "gilberto@poncesolutions.com",
        component: Dashboard,
        layout: "/auth",
      },
    ],
  },
];

export default routes;
