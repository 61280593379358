import axios from "axios";
import * as d3 from "d3";

export function requestGeneral(params) {
  console.log("params", params);
  let url = "";
  const tmp = params.url.split("/");
  //console.log("tmp", tmp.length);
  if (window.location.port * 1 < 4000) {
    url = params.url;

    //console.log("url", url);
  } else {
    console.log("less", params.url.includes("tokenAPI"), url);
    if (params.url.includes("tokenAPI")) {
      url = params.url;
    } else {
      if (tmp.length === 4) {
        url = "/" + tmp[3];
      } else {
        url = "/" + tmp[3] + "/" + tmp[4];
      }
    }
  }
  //console.log("url", url);
  let formData = null;

  if (params.method === "post") {
    //console.log(url);
    if (url.includes("tokenAPI")) {
      formData = new FormData();
      formData.append("action", params.action);
      formData.append("email", params.email);
      formData.append("uuid", params.uuid);
      formData.append("token", params.token);
      console.log("here 0");
      return axios.post(url, formData);
      //return axios.post(url, params,{
      //headers: {
      /// Overwrite Axios's automatically set Content-Type
      //'Content-Type': 'application/json'
      //}
      //})
    } else {
      //formData = params
      //console.log("here 1");
      return axios.post(url, params, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      });
    }
  } else {
    //console.log("params", params);
    return axios.get(url, {
      params: params,
    });
  }
}

export function requestViewFile(params) {
  //console.log(params);
  let filePath =
    "itemMaster/" +
    params.fileName +
    "?check=" +
    Math.floor(Math.random() * 1000);

  switch (params.page) {
    case "inventory":
      filePath =
        params.url +
        "/data/" +
        params.fileName +
        "?check=" +
        Math.floor(Math.random() * 1000);
      break;
    case "itemMaster":
      filePath =
        params.url +
        "/itemMaster/" +
        params.fileName +
        "?check=" +
        Math.floor(Math.random() * 1000);
      break;
    case "home":
      filePath =
        "/data/" +
        params.fileName +
        "?check=" +
        Math.floor(Math.random() * 1000);
      break;
    default:
      break;
  }

  //console.log("filePath", filePath);

  return d3.csv(filePath, {
    //return d3.csv(filePath, {
    headers: new Headers({
      "Content-Type": "application/csv",
      Accept: "application/csv",
    }),
  });
}
