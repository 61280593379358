/*!

*/
import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// node.js library that concatenates classes (strings)

// javascipt plugin for creating charts

// react plugin used to create charts

import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";
// reactstrap components
import { Container, Card, CardHeader } from "reactstrap";

// core components

import CheckoutForm from "./CheckoutForm";
import { getApps } from "redux/slices/AppsSlice";

//const stripePromise = loadStripe("pk_test_as5DCSXMyQFiNJq9EDK2FS8L"); //
const stripePromise = loadStripe("pk_live_dHfZvbdGbJrw44AXdU5rAOCP"); //
///var stripe = Stripe('pk_live_dHfZvbdGbJrw44AXdU5rAOCP');

function Payment() {
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState("");
  const [items, setITems] = useState({ items: [{ id: "xl-tshirt" }] });
  const [app, setApp] = useState({});

  const { apps } = useSelector((state) => state.apps);

  const { ip } = useSelector((state) => state.ip);
  let appID = useRouteMatch("/auth/app/:id").params.id * 1;

  //console.log("appID", appID, apps, ip, clientSecret, app.id);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    //http://localhost/stripe/stripeAPI.php

    ip.length === 1 &&
      app &&
      app.id &&
      fetch(ip[0].ip + "/stripe/stripeAPI.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ action: "createIntent", item: app }),
      })
        .then((res) => {
          console.log(res);
          //console.log(res.json());
          return res.json();
        })
        .then((data) => {
          console.log(data);
          setClientSecret(data.clientSecret);
        });
  }, [app, ip]);

  React.useEffect(() => {
    ip.length === 1 &&
      dispatch(
        getApps({
          action: "getApps",
          url:
            ip[0].websocket_port === 80
              ? ip[0].ip + "/stripe/dbAPI.php"
              : ip[0].ip + ":" + ip[0].websocket_port + "/stripe/dbAPI.php",
          page: "home",
        })
      );
  }, [dispatch, ip]);

  React.useEffect(() => {
    if (apps.length > 0) {
      const tempApp = apps.filter((d) => {
        return d.id === appID;
      });
      if (tempApp.length === 1) {
        setApp(tempApp[0]);
      }
      //console.log(tempApp);
    }
  }, [appID, apps]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <SimpleHeader name="Dashboard" parentName="Dashboards" />
      <Container className="mt--6" fluid>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm id={app.id} />
          </Elements>
        )}
      </Container>
    </>
  );
}

export default Payment;
//
