/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

// core components
import AuthNavbar from "components/Navbars/AuthNavbarPonce.js";
import AuthFooter from "components/Footers/AuthFooterPonce.js";

import routes from "routes.js";

import { getSettings } from "../redux/slices/SettingsSlice";
import { viewIP } from "redux/slices/IPSlice";
import Auditors from "views/pages/reports/Auditors";
import Areas from "views/pages/reports/Areas";
import Sections from "views/pages/reports/Sections";
import AreaSection from "views/pages/reports/AreaSection";
import AreaSections from "views/pages/reports/AreaSections";
import Details from "views/pages/reports/Details";
import Payment from "views/pages/stripe/Payment";
import ThankYou from "views/pages/stripe/ThankYou";

function Auth() {
  const dispatch = useDispatch();
  const location = useLocation();
  const mainContentRef = React.useRef(null);

  const { ip } = useSelector((state) => state.ip);
  const { settings } = useSelector((state) => state.settings);

  //console.log(ip);

  React.useEffect(() => {
    //console.log("here");
    ip.length === 0 &&
      dispatch(
        viewIP({
          action: "viewFile",
          customerID: 0,
          page: "home",
          fileName: "ip.csv",
        })
      );
  }, [dispatch, ip.length]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    document.body.classList.add("bg-default");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("bg-default");
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  /*
  {
        path: "/areas",
        name: "Areas",
        miniName: "Areas",
        component: Areas,
        layout: "/admin",
      },
      {
        path: "/sections",
        name: "Sections",
        miniName: "Sec",
        component: Sections,
        layout: "/admin",
      },
      {
        path: "/departments",
        name: "Departments",
        miniName: "Dept",
        component: Departments,
        layout: "/admin",
      },
  */

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <AuthNavbar />
        <Switch>
          {getRoutes(routes)}
          {/* <Route exact path="/auth/area/:area" component={() => <AreaSections showLog={true} />} />
          <Route exact path="/auth/areaSection/:areaSection" component={() => <AreaSection showLog={true} />} />
          <Route exact path="/auth/details/:type/:id" component={() => <Details showLog={true} />} /> */}
          <Route
            exact
            path="/auth/auditors"
            component={() => <Auditors showLog={true} />}
          />
          <Route
            exact
            path="/auth/areas"
            component={() => <Areas showLog={true} />}
          />
          <Route
            exact
            path="/auth/sections"
            component={() => <Sections showLog={true} />}
          />
          <Route
            exact
            path="/auth/app/:id"
            component={() => <Payment showLog={true} />}
          />
          <Route
            exact
            path="/auth/complete/:id"
            component={() => <ThankYou showLog={true} />}
          />
          <Redirect from="*" to="/" />
          auditors
        </Switch>
      </div>
      <AuthFooter />
    </>
  );
}

export default Auth;
