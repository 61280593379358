import { createSlice } from "@reduxjs/toolkit";

const customersSlice = createSlice({
  name: "customers",
  initialState: {customers:[]},
  reducers: {
    getCustomers() {
     
    },
    loadCustomerInfo(){

    },
    setCustomers(state, action) {
      
      const customersData = action.payload;
     
      //return  {...state, ...action.payload};
      return  {...state, customers:customersData};
    },
    setCustomer(state, action) {
      const customerData = action.payload;
      console.log(customerData);

      

      const custs = [
        ...state.customers.filter((d) => {
          //d.selected=null;
          if (d.id === customerData.id) {
            return false;
          } else {
            return true;
          }
        }),
        customerData,
      ];
      custs.forEach(d =>{
        if (d.id !== customerData.id) {
          d.selected=null
        } 
      }) 

      return { ...state, customers: custs };
    },
   
    
  },
});

export const { getCustomers, loadCustomerInfo, setCustomers, setCustomer, setCustomerStoreNumber } = customersSlice.actions;

export default customersSlice.reducer;
