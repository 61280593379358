import { createSlice } from "@reduxjs/toolkit";

const inventoryOutputSlice = createSlice({
  name: "inventoryOutput",
  initialState: {
    file_name: "",
    file_type: "",
    items: [],
    total: {
      entries: 0,
      quantities: 0,
      amount: 0,
    },
  },
  reducers: {
    setInventoryOutput(state, action) {
      return { ...state, ...action.payload };
    },

    viewInventoryOutput() {},
  },
});

export const { setInventoryOutput, viewInventoryOutput } = inventoryOutputSlice.actions;

export default inventoryOutputSlice.reducer;
