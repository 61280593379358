/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as d3 from "d3";

// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Collapse,
} from "reactstrap";
import { getDashboardInfo } from "redux/slices/DashboardSlice";

function Totals({ name, parentName }) {
  const dispatch = useDispatch();

  var formatDecimal2 = d3.format(",.2f");

  const { dashboardInfo } = useSelector((state) => state.dashboardInfo);
  const { dashboardAreaSummary } = useSelector((state) => state.dashboardInfo);
  const { dashboardAuditorSummary } = useSelector(
    (state) => state.dashboardInfo
  );
  const { dashboardSectionSummary } = useSelector(
    (state) => state.dashboardInfo
  );
  const { dashboardFileSummary } = useSelector((state) => state.dashboardInfo);
  const { ip } = useSelector((state) => state.ip);

  React.useEffect(() => {
    ip.length === 1 &&
      dispatch(
        getDashboardInfo({
          action: "getDashboardInfo",
          method: "get",
          url: "http://" + ip[0].ip + ":" + ip[0].websocket_port + "/dashboard",
        })
      );
  }, [dispatch, ip, ip.length]);

  const [isAreaOpen, setAreaIsOpen] = useState(false);
  const toggleArea = () => setAreaIsOpen(!isAreaOpen);

  const [isAuditorOpen, setAuditorIsOpen] = useState(false);
  const toggleAuditor = () => setAuditorIsOpen(!isAuditorOpen);

  const [isFileOpen, setFileIsOpen] = useState(false);
  const toggleFile = () => setFileIsOpen(!isFileOpen);

  const [isSectionOpen, setSectionIsOpen] = useState(false);
  const toggleSection = () => setSectionIsOpen(!isSectionOpen);

  return (
    <>
      <div className="header pb-6 pt-3">
        <Container fluid>
          <div className="header-body">
            <Row>
              {dashboardInfo.map((d) => (
                <Col md="6" xl={d.type === "percent" ? "3" : "6"} key={d.title}>
                  {(d.title.toLowerCase() === "areas" ||
                    d.title.toLowerCase() === "sections") && (
                    <Link to={"/admin/" + d.title.toLowerCase()}>
                      <Card className="card-stats bg-gradient-primary ">
                        <CardBody>
                          <Row className="text-center">
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0 text-white"
                              >
                                {d.title}
                              </CardTitle>
                              {d.type === "percent" && (
                                <span className="h2 font-weight-bold mb-0 text-white">
                                  {d.counted.length} of {d.defined.length}
                                </span>
                              )}
                            </div>
                          </Row>
                          {d.type === "total" && (
                            <>
                              <Row className="text-center">
                                <Col>Entries</Col>
                                <Col>Qty</Col>
                                <Col>Total</Col>
                              </Row>
                              <Row className="text-center">
                                <Col>{d.count}</Col>
                                <Col>{d.quantity}</Col>
                                <Col>{formatDecimal2(d.total)}</Col>
                              </Row>
                            </>
                          )}

                          {d.type === "percent" && (
                            <p className="mt-3 mb-0 text-sm text-center">
                              <span className="text-white mr-2">
                                {d.defined.length > 0 &&
                                  formatDecimal2(
                                    (d.counted.length * 100) / d.defined.length
                                  ) + "% completed"}
                              </span>{" "}
                            </p>
                          )}
                          {d.type === "percent" && (
                            <p className="mt-3 mb-0 text-sm text-center">
                              {d.defined.length > 0 &&
                                (d.counted.length * 100) / d.defined.length >
                                  100 && (
                                  <span className="text-success mr-2">
                                    Sections needed to be defined
                                  </span>
                                )}{" "}
                            </p>
                          )}
                        </CardBody>
                      </Card>
                    </Link>
                  )}
                  {d.title.toLowerCase() !== "areas" &&
                    d.title.toLowerCase() !== "sections" && (
                      <Card className="card-stats bg-gradient-primary ">
                        <CardBody>
                          <Row className="text-center">
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0 text-white"
                              >
                                {d.title}
                              </CardTitle>
                              {d.type === "percent" && (
                                <span className="h2 font-weight-bold mb-0">
                                  {d.counted.length} of {d.defined.length}
                                </span>
                              )}
                            </div>
                          </Row>
                          {d.type === "total" && (
                            <>
                              <Row className="text-center text-white">
                                <Col>Entries</Col>
                                <Col>Qty</Col>
                                <Col>Total</Col>
                              </Row>
                              <Row className="text-center text-white">
                                <Col>{d.count}</Col>
                                <Col>{d.quantity}</Col>
                                <Col>{formatDecimal2(d.total)}</Col>
                              </Row>
                            </>
                          )}

                          {d.type === "percent" && (
                            <p className="mt-3 mb-0 text-sm text-center">
                              <span className="text-success mr-2">
                                {d.defined.length > 0 &&
                                  formatDecimal2(
                                    (d.counted.length * 100) / d.defined.length
                                  ) + "% completed"}
                              </span>{" "}
                            </p>
                          )}
                        </CardBody>
                      </Card>
                    )}
                </Col>
              ))}
            </Row>

            <Row>
              <Col>
                <Card className="card-plain ">
                  <CardHeader
                    className="card-stats bg-gradient-info "
                    role="tab"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={toggleArea}
                  >
                    <h5 className="mb-0 ">
                      Areas Totals - Area - Entries - Quantity - Total
                    </h5>
                  </CardHeader>
                  <Collapse role="tabpanel" isOpen={isAreaOpen}>
                    <CardBody className="card-stats bg-gradient-default ">
                      <Row>
                        {dashboardAreaSummary &&
                          dashboardAreaSummary.map((d) => (
                            <Col md="4" lg="3" sm="6" xs="12" key={d.inv_area}>
                              <Link to={"/admin/area/" + d.inv_area}>
                                <Button
                                  id={"btn-area-summary-" + d.inv_area}
                                  block
                                  outline
                                >
                                  <Badge>{d.inv_area}</Badge> {d.count}{" "}
                                  <Badge>{d.inv_quantity}</Badge>{" "}
                                  {formatDecimal2(d.total)}
                                </Button>
                              </Link>
                            </Col>
                          ))}
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="card-plain">
                  <CardHeader
                    className="card-stats bg-gradient-info "
                    role="tab"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={toggleSection}
                  >
                    <h5 className="mb-0">
                      Section Totals - Section - Entries - Quantity - Total
                    </h5>
                  </CardHeader>
                  <Collapse role="tabpanel" isOpen={isSectionOpen}>
                    <CardBody className="card-stats bg-gradient-default ">
                      <Row>
                        {dashboardSectionSummary &&
                          dashboardSectionSummary.map((d) => (
                            <Col
                              md="4"
                              lg="3"
                              sm="6"
                              xs="12"
                              key={d.inv_section}
                            >
                              <Link to={"/admin/section/" + d.inv_section}>
                                <Button
                                  id={"btn-section-summary-" + d.inv_section}
                                  block
                                  outline
                                >
                                  <Badge color="primary">{d.inv_section}</Badge>{" "}
                                  {d.count}{" "}
                                  <Badge color="info">{d.inv_quantity}</Badge>{" "}
                                  {formatDecimal2(d.total)}
                                </Button>
                              </Link>
                            </Col>
                          ))}
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="card-plain">
                  <CardHeader
                    className="card-stats bg-gradient-info "
                    role="tab"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={toggleAuditor}
                  >
                    <h5 className="mb-0">
                      Auditor Totals - Auditor - Entries - Quantity - Total
                    </h5>
                  </CardHeader>
                  <Collapse role="tabpanel" isOpen={isAuditorOpen}>
                    <CardBody className="card-stats bg-gradient-default ">
                      <Row>
                        {dashboardAuditorSummary &&
                          dashboardAuditorSummary.map((d) => (
                            <Col
                              md="6"
                              lg="4"
                              sm="12"
                              xs="12"
                              key={d.inv_auditor}
                            >
                              <Link to={"/admin/auditor/" + d.inv_auditor}>
                                <Button
                                  id={"btn-auditor-summary-" + d.inv_auditor}
                                  block
                                  outline
                                >
                                  <Badge color="primary">{d.inv_auditor}</Badge>{" "}
                                  {d.count}{" "}
                                  <Badge color="info">{d.inv_quantity}</Badge>{" "}
                                  {formatDecimal2(d.total)}
                                </Button>
                              </Link>
                            </Col>
                          ))}
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="card-plain">
                  <CardHeader
                    className="card-stats bg-gradient-info "
                    role="tab"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={toggleFile}
                  >
                    <h5 className="mb-0">
                      File Totals - File - Entries - Quantity - Total (unique
                      part of files)
                    </h5>
                  </CardHeader>
                  <Collapse role="tabpanel" isOpen={isFileOpen}>
                    <CardBody className="card-stats bg-gradient-default ">
                      <Row>
                        {dashboardFileSummary &&
                          dashboardFileSummary.map((d) => (
                            <Col
                              md="6"
                              lg="4"
                              sm="12"
                              xs="12"
                              key={d.inv_file_name}
                            >
                              <Button
                                id={"btn-file-summary-" + d.inv_file_name}
                                block
                                outline
                              >
                                <Badge color="primary">
                                  {d.inv_file_name.includes("inventory")
                                    ? d.inv_file_name.split("_")[1]
                                    : d.inv_file_name}
                                </Badge>{" "}
                                {d.count}{" "}
                                <Badge color="info">{d.inv_quantity}</Badge>{" "}
                                {formatDecimal2(d.total)}
                              </Button>
                            </Col>
                          ))}
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

Totals.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default Totals;
