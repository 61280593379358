/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components


import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { setIndicator } from "redux/slices/IndicatorSlice";
import { setMessage } from "redux/slices/MessageSlice";
import { setCustomers} from "redux/slices/SetupCustomersSlice";
import { setCustomerLocations } from "redux/slices/SetupCustomerLocationsSlice";

function AddCustomerLocation({customerID, hideModal}) {
  
  const dispatch = useDispatch();
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const { ip } = useSelector((state) => state.ip);
  const [customerLocation, setCustomerLocation] = useState({
    customer_id:customerID,
    store_number: "",
    email:"",
    location_name:"",
    customer_type:0,
    address_01: "",
    address_02: "",
    city:"",
    state:"",
    postal_code:"",
    is_converting_pack_to_single_flag:0,
    is_expanding_upc_flag:0,
    export_file:"",
    item_master_file:"",
    no_found_range:3,
    backroom_section_start:"",
    backroom_section_end:"",
    is_using_store_number_flag:0,
    is_file_conversion_required_flag:0,
    is_financial_inventory_flag:0,
    batch_file:""
  });

  function handleChange(event) {
    console.log("handleChange")
    console.log(event.target);
    var {name, value} = event.target;

    if (name.includes("flag")) {
      console.log(customerLocation[name]);
      value  = customerLocation[name] === 0?1:0

    }

    setCustomerLocation(prevValue =>{
      return {
        ...prevValue,
        [name]:value
      }
    })
    console.log(customerLocation);
  }

  function handleClick(event) {
    console.log("handleClick", customerLocation)
    let tmp = null;
   if (event.target.children.length > 0) {
     tmp = event.target.id.split("-");
     
   } else {
     tmp = event.target.parentElement.id.split("-");
   
   }
   console.log(tmp);
   dispatch(setIndicator(true));
   ip.length === 1 && customerLocation.store_number.length > 0 &&
        axios
          .post("http://" + ip[0].ip +":" + ip[0].websocket_port + "/addCustomerLocation", {data:customerLocation})
          .then((response) => {
            console.log(response);
            //dispatch(
              //setMessage({ message: response.data.message, variant: "info" })
            //);
            response.data.data.forEach(d=>{
              d.key = "" + d.customer_id + d.store_number
            })
            dispatch(setCustomerLocations(response.data.data))
            dispatch(setIndicator(false));
            hideModal(false)
            //callback(response.data);
          })
          .catch((error) => {
            dispatch(setIndicator(false));
            dispatch(setMessage({ message: error, variant: "danger" }));
            console.log(error);
            //console.log("*****  "+error)
          });
    //setModalStores(true)
    //console.log(event.target.id);
 }


  return (
    <>
      <Card className="bg-secondary border-0 mb-0">
        <CardHeader className="bg-transparent pb-5">
          <div className="text-muted text-center mt-2 mb-3">
            <small>Add Customer Location</small>
          </div>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
          <Form role="form">
          <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  placeholder="Location Name"
                  type="text"
                  name="location_name"
                  value={customerLocation.location_name}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
              className={classnames("mb-3", {
                focused: focusedEmail,
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  type="text"
                  placeholder="Store Number"
                  name="store_number"
                  value={customerLocation.store_number}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  placeholder="Email"
                  type="text"
                  name="email"
                  value={customerLocation.email}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  placeholder="Address"
                  type="text"
                  name="address_01"
                  value={customerLocation.address}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  placeholder="City"
                  type="text"
                  name="city"
                  value={customerLocation.city}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  placeholder="State"
                  type="text"
                  name="state"
                  value={customerLocation.state}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  placeholder="Postal Code"
                  type="number"
                  name="postal_code"
                  value={customerLocation.postal_code}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  placeholder="Export File"
                  type="text"
                  name="export_file"
                  value={customerLocation.export_file}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  placeholder="Item Master File"
                  type="text"
                  name="item_master_file"
                  value={customerLocation.item_master_file}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  placeholder="No Found Range"
                  type="number"
                  name="no_found_range"
                  value={customerLocation.no_found_range}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  placeholder="Backroom section start"
                  type="text"
                  name="backroom_section_start"
                  value={customerLocation.backroom_section_start}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  placeholder="Backroom section end"
                  type="text"
                  name="backroom_section_end"
                  value={customerLocation.backroom_section_end}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm={9}>Is converting pack to single?</Col>
                <Col sm={3}>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange}
                      name="is_converting_pack_to_single_flag"
                      value={customerLocation.is_converting_pack_to_single_flag}
                      type="checkbox"
                      checked={
                        customerLocation.is_converting_pack_to_single_flag === 1
                          ? true
                          : false
                      }
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm={9}>Is expanding UPC?</Col>
                <Col sm={3}>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange}
                      name="is_expanding_upc_flag"
                      value={customerLocation.is_expanding_upc_flag}
                      type="checkbox"
                      checked={
                        customerLocation.is_expanding_upc_flag === 1
                          ? true
                          : false
                      }
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm={9}>Is using store number?</Col>
                <Col sm={3}>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange}
                      name="is_using_store_number_flag"
                      value={customerLocation.is_using_store_number_flag}
                      type="checkbox"
                      checked={
                        customerLocation.is_using_store_number_flag === 1
                          ? true
                          : false
                      }
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm={9}>Is file conversion required?</Col>
                <Col sm={3}>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange}
                      name="is_file_conversion_required_flag"
                      value={customerLocation.is_file_conversion_required_flag}
                      type="checkbox"
                      checked={
                        customerLocation.is_file_conversion_required_flag === 1
                          ? true
                          : false
                      }
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm={9}>Is financial inventory?</Col>
                <Col sm={3}>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange}
                      name="is_financial_inventory_flag"
                      value={customerLocation.is_financial_inventory_flag}
                      type="checkbox"
                      checked={
                        customerLocation.is_financial_inventory_flag === 1
                          ? true
                          : false
                      }
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </Col>
              </Row>
            </FormGroup>

            <div className="text-center">
              <Button
                disabled={
                  customerLocation.store_number.length > 0 ? false : true
                }
                className="my-4"
                onClick={handleClick}
                color="info"
                type="button"
              >
                Save Customer Location
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

export default AddCustomerLocation;
