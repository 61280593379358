import { createSlice } from "@reduxjs/toolkit";

const retailFinancialInventorySlice = createSlice({
  name: "retailFinancialInventory",
  initialState: { retailFinancialInventory: [] },
  reducers: {
    createFile() {},
    getRetailFinancialInventory() {},
    processInventory() {},

    setRetailFinancialInventory(state, action) {
      const customersData = action.payload;

      //console.log(customersData);

      return { ...state, retailFinancialInventory: customersData };
    },
    updateFinancialInventory() {},
    //viewInventoryOutput(){},
  },
});

export const {
  createFile,
  getRetailFinancialInventory,
  setRetailFinancialInventory,
  processInventory,
  updateFinancialInventory,
} = retailFinancialInventorySlice.actions;

export default retailFinancialInventorySlice.reducer;
