/*!

*/
import React, { useState } from "react";
// react plugin that prints a given react component

// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import ReactBSAlert from "react-bootstrap-sweetalert";
// react component used to create sweet alerts

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Col,
  Card,
  Container,
  Row,
  Button,
  Form,
  Modal,
  CardHeader,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";

import { setMessage } from "redux/slices/MessageSlice";

import {
  getCustomerLocations,
  loadCustomerLocationsFile,
} from "redux/slices/SetupCustomerLocationsSlice";
import { addCustomerLocation } from "redux/slices/SetupCustomerLocationsSlice";
import { deleteCustomerLocation } from "redux/slices/SetupCustomerLocationsSlice";
import { updateCustomerLocation } from "redux/slices/SetupCustomerLocationsSlice";
import { setIndicator } from "redux/slices/IndicatorSlice";
import axios from "axios";

import { useRouteMatch } from "react-router-dom";
import AddCustomerLocation from "./AddCustomerLocation";

//import CustomerLocations from "./CustomerLocations";

//import { dataTable } from "variables/general";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,

  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function SetupCustomerLocations() {
  const dispatch = useDispatch();

  const customerId = useRouteMatch("/admin/setupCustomer/:id").params.id;

  const [CustomerLocations, setCustomerLocations] = useState({
    from: "",
    to: "",
  });
  const [fileName, setFileName] = useState("inventory_customer_locations.csv");
  const { ip } = useSelector((state) => state.ip);
  const { settings } = useSelector((state) => state.settings);
  const { setupCustomerLocations } = useSelector(
    (state) => state.setupCustomerLocations
  );
  const [customerLocationToDelete, setCustomerLocationToDelete] = useState({
    customer_id: 0,
    store_number: "",
  });
  const [modalStores, setModalStores] = React.useState(false);
  const [tableColumns, setTableColumns] = useState([
    {
      dataField: "customer_id",
      headerAlign: "left",
      text: "ID",
      sort: true,
      editable: false,

      csvType: "number",
      align: (cell, row, rowIndex, colIndex) => {
        return "left";
      },
      hidden: false,
    },
    {
      dataField: "is_converting_to_pack_flag",
      headerAlign: "left",
      text: "Name",
      sort: true,
      editable: false,
      csvType: "number",
      align: (cell, row, rowIndex, colIndex) => {
        return "left";
      },
      hidden: false,
      //formatter: valueFormatter,
    },
  ]);

  //console.log(tableColumns);

  //console.log("SetupCustomerLocations", customerId);

  const setCustomerLocationDeleteInfo = {
    title: "Are you sure?",
    confirm:
      "Yes, delete Customer ID:" +
      customerLocationToDelete.customer_id +
      " Store number:" +
      customerLocationToDelete.store_number,
    message: <span>You wont be able to revert this!</span>,
    cancel: "Cancel",
  };

  const setCustomerLocationsLoadingInfo = {
    title: "Are you sure?",
    confirm: "Yes, load CustomerLocations",
    message: <span>You wont be able to revert this!</span>,
    cancel: "Cancel",
  };

  const setCustomerRangeInfo = {
    title: "Are you sure?",
    confirm: "Yes, set Customer range",
    message: (
      <span>
        You wont be able to revert this!
        <br />
        This will only replace CustomerLocations within the range
      </span>
    ),
    cancel: "Cancel",
  };

  const [isCustomerRangeAlertShowing, setIsCustomerRangeAlertShowing] =
    useState(false);
  const [
    isCustomerLocationsLoadingAlertShowing,
    setIsCustomerLocationsLoadingAlertShowing,
  ] = useState(false);
  const [
    isCustomerLocationDeleteAlertShowing,
    setIsCustomerLocationDeleteAlertShowing,
  ] = useState(false);

  const confirmAlert = (whichAlert) => {
    //console.log("confirmAlert", whichAlert)
    //setIsCustomerRangeAlertShowing(false);
    switch (whichAlert) {
      case "customerDelete":
        if (customerLocationToDelete.customer_id.length > 0) {
          ip.length === 1 &&
            dispatch(
              deleteCustomerLocation({
                url:
                  "http://" +
                  ip[0].ip +
                  ":" +
                  ip[0].websocket_port +
                  "/deleteCustomerLocation",
                action: "deleteCustomerLocation",
                data: customerLocationToDelete,
                method: "post",
              })
            );
        }
        setIsCustomerLocationDeleteAlertShowing(false);
        break;
      case "customerLocationsLoading":
        ip.length === 1 &&
          dispatch(
            loadCustomerLocationsFile({
              action: "loadCustomerLocationsFile",
              method: "get",
              url:
                "http://" +
                ip[0].ip +
                ":" +
                ip[0].websocket_port +
                "/loadCustomerLocationsFile/" +
                fileName,
              customerID: customerId,

              fileName: fileName,
            })
          );
        setIsCustomerLocationsLoadingAlertShowing(false);
        break;
      case "customerRange":
        if (
          CustomerLocations.from.length > 0 &&
          CustomerLocations.from * 1 <= 9999 &&
          CustomerLocations.from * 1 > 0 &&
          CustomerLocations.to.length > 0 &&
          CustomerLocations.to * 1 <= 9999 &&
          CustomerLocations.to * 1 > 0 &&
          settings.length === 1
        ) {
          ip.length === 1 &&
            dispatch(
              addCustomerLocation({
                url:
                  "http://" +
                  ip[0].ip +
                  ":" +
                  ip[0].websocket_port +
                  "/addCustomerLocations",
                action: "addCustomerLocations",
                data: CustomerLocations,
                method: "post",
              })
            );
        } else {
          dispatch(
            setMessage({
              message:
                "CustomerLocations need to be numeric and between 0 and 9999. Please update as needed",
              variant: "danger",
            })
          );
        }
        setIsCustomerRangeAlertShowing(false);
        break;
      default:
        break;
    }
  };

  const showAlert = (whichAlert) => (event) => {
    //console.log(whichAlert, event.target.id +"<");
    switch (whichAlert) {
      case "customerDelete":
        let tmp = null;
        if (event.target.children.length > 0) {
          tmp = event.target.id.split("-");
        } else {
          tmp = event.target.parentElement.id.split("-");
        }
        //console.log(tmp);
        setCustomerLocationToDelete({
          customer_id: tmp[3],
          store_number: tmp[4],
        });
        setIsCustomerLocationDeleteAlertShowing(true);
        break;
      case "customerLocationsLoading":
        setIsCustomerLocationsLoadingAlertShowing(true);
        break;
      case "customerRange":
        setIsCustomerRangeAlertShowing(true);
        break;
      default:
        break;
    }
  };

  const cancelAlert = (whichAlert) => {
    //console.log("cancel",whichAlert)

    switch (whichAlert) {
      case "customerDelete":
        setIsCustomerLocationDeleteAlertShowing(false);
        break;
      case "customerLocationsLoading":
        setIsCustomerLocationsLoadingAlertShowing(false);
        break;
      case "customerRange":
        setIsCustomerRangeAlertShowing(false);
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    //console.log("SetupCustomerLocations useEffect", customerId);

    ip.length > 0 &&
      dispatch(
        getCustomerLocations({
          action: "getCustomerLocations",
          method: "get",
          url:
            "http://" +
            ip[0].ip +
            ":" +
            ip[0].websocket_port +
            "/CustomerLocations/" +
            customerId,
        })
      );
    customerId &&
      setFileName("inventory_customer_locations_" + customerId + ".csv");
  }, [dispatch, settings, ip, customerId]);

  React.useEffect(() => {
    //setupCustomerLocations.length > 1 && updateColumns(setupCustomerLocations[0])
    //function updateColumns(data) {
    if (setupCustomerLocations.length > 0) {
      const cols = [];
      const keys = Object.keys(setupCustomerLocations[0]);
      for (let i = 0; i < keys.length; i++) {
        //console.log(keys[i]);
        const aligning =
          isRegProperty(setupCustomerLocations[0][keys[i]]) === "number"
            ? "right"
            : "left";
        let isDummy;
        let isEditable = true;
        if (
          keys[i] === "customer_id" ||
          keys[i] === "store_number" ||
          keys[i] === "key"
        ) {
          isEditable = false;
        }

        var c;
        if (keys[i].includes("flag")) {
          //console.log("it is flag")
          c = {
            dataField: keys[i],
            text: keys[i],
            sort: true,
            headerAlign: aligning,
            align: aligning,
            editable: isEditable,
            //formatter: valueFormatter,
            formatExtraData: { column: keys[i] },

            editor: {
              type: Type.CHECKBOX,
              value: "1:0",
            },
            //editable: editableValue,
            //ditorRenderer:
            csvType: "number",
          };
        } else if (keys[i] !== "key") {
          c = {
            dataField: keys[i],
            text: keys[i],
            sort: true,
            headerAlign: aligning,
            align: aligning,
            editable: isEditable,
            editorStyle: { width: "200px" },
            //editable: editableValue,
            //ditorRenderer:
            csvType: "number",
          };
        } else {
          c = {
            dataField: keys[i],
            text: keys[i],
            sort: true,
            headerAlign: aligning,
            align: aligning,
            editable: isEditable,
            editorStyle: { width: "200px" },
            hidden: true,
            //editable: editableValue,
            //ditorRenderer:
            csvExport: false,
          };
          //console.log(c);
        }
        cols.push(c);
        //const tmpColumn = currentColumns.filter(d=> d.column_title === keys[i]);
      }

      const actionCol = {
        dataField: "df1",
        csvExport: false,
        isDummyField: true,
        text: "Action",
        editable: false,
        formatter: (cellContent, row) => {
          //console.log("cell", row);

          return (
            <>
              {/* <Button
                    className="buttons-copy buttons-html5"
                    color="success"
                    size="sm"
                    onClick={handleClick}
                    id={"btn-customer-id-" + row.id}
                  >
                    <i
                      id={"btn-select-" + row.key}
                      title="Go to this customer stores"
                      className="fa fa-list "
                      aria-hidden="true"
                    ></i>
                  </Button>  */}

              <Button
                className="buttons-copy buttons-html5"
                size="sm"
                title={"Delete customer " + row.customer_id}
                onClick={showAlert("customerDelete")}
                id={
                  "btn-delete-customer-" +
                  row.customer_id +
                  "-" +
                  row.store_number
                }
              >
                <i
                  className="fa fa-trash fa-2x text-danger"
                  aria-hidden="true"
                ></i>
              </Button>
            </>
          );
        },
      };
      cols.push(actionCol);

      setTableColumns(cols);
      //setLocationsTable([...setupCustomerLocations])
      //console.log("locationsTable1",locationsTable);
      //}
    }
  }, [setupCustomerLocations]);

  function handleClick(event) {
    console.log("handleClick");
    let tmp = null;
    if (event.target.children.length > 0) {
      tmp = event.target.id.split("-");
    } else {
      tmp = event.target.parentElement.id.split("-");
    }
    console.log(tmp);
    setModalStores(true);
    //console.log(event.target.id);
  }

  function handleCustomerChange(event) {
    //console.log("handleCustomerChange")
    //console.log(event.target);
    const { name, value } = event.target;
    setCustomerLocations((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
    //console.log(CustomerLocations);
  }

  function isRegProperty(obj) {
    var t = typeof obj;
    return t;
  }

  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file

  const { ExportCSVButton } = CSVExport;

  function handleFile(e) {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      //if((!!file.name.match(/(\.|\/)(csv)$/i)) || (!!file.name.match(/(\.|\/)(txt)$/i)) ){
      //if (!!file.name.match(/(inventoryCustomerLocations\.|\/)(csv)$/i)) {
      if (
        file.name.includes("inventory_customer_locations") &&
        file.name.includes(".csv")
      ) {
        //console.log("handlefile " + file.name);
        setFileName(file.name);
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        /* const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        }; */
        dispatch(setIndicator(true));
        ip.length === 1 &&
          axios
            .post(
              "http://" +
                ip[0].ip +
                ":" +
                ip[0].websocket_port +
                "/customCustomerLocations",
              formData
            )
            .then((response) => {
              //console.log(response);
              dispatch(
                setMessage({ message: response.data.message, variant: "info" })
              );
              dispatch(setIndicator(false));
              //callback(response.data);
            })
            .catch((error) => {
              dispatch(setIndicator(false));
              dispatch(setMessage({ message: error, variant: "danger" }));
              console.log(error);
              //console.log("*****  "+error)
            });
      } else {
        dispatch(
          setMessage({
            message:
              "Only inventory_customer_locations_xxxxx.csv file is accepted",
            variant: "danger",
          })
        );
        //console.log("file does not match");
      }
    }
  }

  return (
    <>
      <SimpleHeader name="" parentName="Setup" />

      <Container className="mt--6" fluid>
        <Card className="card-stats">
          <CardHeader className="border-0">
            <Row>
              <Col sm={6}>
                <h3 className="mb-0">Set Customer Locations</h3>
              </Col>
              <Col sm={6}>
                <Button
                  className="btn-icon btn-3"
                  color="primary"
                  onClick={handleClick}
                  type="button"
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span className="btn-inner--text">Add Customer Location</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <Form>
            <div className="custom-file">
              <input
                className="custom-file-input"
                id="customFileLang"
                lang="en"
                type="file"
                onChange={handleFile}
              />

              <label className="custom-file-label" htmlFor="customFileLang">
                Select file
              </label>
            </div>
          </Form>

          <Row>
            <Col xs={12}>
              <Button
                className="btn-block"
                //onClick={handleFileLoad}
                onClick={showAlert("customerLocationsLoading")}
                color="primary"
                type="button"
                id="btn-load-area-file"
              >
                Load {fileName} file
              </Button>
            </Col>
          </Row>
          <Row>
            <div className="col">
              <Card>
                {setupCustomerLocations.length > 0 && settings.length > 0 && (
                  <ToolkitProvider
                    data={setupCustomerLocations}
                    keyField="key"
                    columns={tableColumns}
                    search
                    exportCSV={{
                      fileName: fileName,
                      separator: ",",
                      blobType: "text/csv;charset=ansi",
                    }}
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        {
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                            <br />

                            <ExportCSVButton {...props.csvProps}>
                              Export CSV {"(" + fileName + ")"}
                            </ExportCSVButton>
                          </div>
                        }

                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          striped
                          defaultSorted={[
                            {
                              dataField: "id",
                              order: "asc",
                            },
                          ]}
                          cellEdit={cellEditFactory({
                            mode: "click",
                            blurToSave: true,
                            beforeSaveCell(
                              oldValue,
                              newValue,
                              row,
                              column,
                              done
                            ) {
                              const newRow = {
                                ...row,
                                [column.dataField]: newValue,
                              };

                              console.log("newRow", newRow);
                              //console.log(setupCustomerLocations)
                              //let temp = [...setupCustomerLocations,newRow];
                              //console.log(temp);
                              //dispatch(setCustomer(newRow));
                              if (ip.length === 1) {
                                dispatch(
                                  updateCustomerLocation({
                                    url:
                                      "http://" +
                                      ip[0].ip +
                                      ":" +
                                      ip[0].websocket_port +
                                      "/updateCustomerLocation",
                                    action: "updateCustomerLocation",
                                    data: newRow,
                                    method: "post",
                                  })
                                );
                              }

                              done(false); // The changes are handled by the dispatch

                              return { async: true };
                            },
                          })}
                          pagination={pagination}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </div>
          </Row>
          {isCustomerRangeAlertShowing && (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title={setCustomerRangeInfo.title}
              onConfirm={() => confirmAlert("customerRange")}
              onCancel={() => cancelAlert("customerRange")}
              showCancel
              cancelBtnBsStyle="secondary"
              confirmBtnText={setCustomerRangeInfo.confirm}
              confirmBtnBsStyle="danger"
              cancelBtnText={setCustomerRangeInfo.cancel}
              btnSize=""
            >
              {setCustomerRangeInfo.message}
            </ReactBSAlert>
          )}
          {isCustomerLocationDeleteAlertShowing && (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title={setCustomerLocationDeleteInfo.title}
              onConfirm={() => confirmAlert("customerDelete")}
              onCancel={() => cancelAlert("customerDelete")}
              showCancel
              cancelBtnBsStyle="secondary"
              confirmBtnText={setCustomerLocationDeleteInfo.confirm}
              confirmBtnBsStyle="danger"
              cancelBtnText={setCustomerLocationDeleteInfo.cancel}
              btnSize=""
            >
              {setCustomerLocationDeleteInfo.message}
            </ReactBSAlert>
          )}
          {isCustomerLocationsLoadingAlertShowing && (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title={setCustomerLocationsLoadingInfo.title}
              onConfirm={() => confirmAlert("customerLocationsLoading")}
              onCancel={() => cancelAlert("customerLocationsLoading")}
              showCancel
              cancelBtnBsStyle="secondary"
              confirmBtnText={setCustomerLocationsLoadingInfo.confirm}
              confirmBtnBsStyle="danger"
              cancelBtnText={setCustomerLocationsLoadingInfo.cancel}
              btnSize=""
            >
              {setCustomerLocationsLoadingInfo.message}
            </ReactBSAlert>
          )}
        </Card>
        <Modal
          isOpen={modalStores}
          toggle={() => setModalStores(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            <AddCustomerLocation
              customerID={customerId}
              hideModal={setModalStores}
            />
          </div>
          <div className="modal-footer">
            <Button
              className="ml-auto"
              color="link"
              onClick={() => setModalStores(false)}
            >
              Close
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
}

export default SetupCustomerLocations;
