import { createSlice } from "@reduxjs/toolkit";

const setupAreasSlice = createSlice({
  name: "setupAreas",
  initialState: { setupAreas: [] },
  reducers: {
    addAreas() {},
    deleteArea() {},
    getAreas(state, action) {
      //console.log("getSections",action);
    },
    loadAreasFile(){},
    setAreas(state, action) {
      //console.log("setSections", action.payload);
      const data = action.payload;

      return { ...state, setupAreas: data };
    },
    setArea(state, action) {
      
      const data = action.payload;
      //console.log(state, data);
      const areas = [
        ...state.setupAreas.filter((d) => {
          if (d.store_area === data.store_area) {
            return false;
          } else {
            return true;
          }
        }),
        data,
      ]; 

      return { ...state, setupAreas: areas };
    },
    updateArea() {},
  },
});

export const { addAreas, deleteArea, getAreas,loadAreasFile, setAreas, setArea, updateArea } = setupAreasSlice.actions;

export default setupAreasSlice.reducer;
