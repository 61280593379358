import { call, put } from "redux-saga/effects";
import { setDashboardInfo } from "redux/slices/DashboardSlice";
import { setIndicator } from "redux/slices/IndicatorSlice";
import { requestGeneral} from "../requests/index";
import { setMessage } from "../../slices/MessageSlice";

export function* handleGetDashboardInfo(action) {
  
  try {
    yield put(setIndicator(true));
     yield put(
      setMessage({
        message: "Gettting dashboard info...",
        variant: "info"
      })
    ); 

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      action: action.payload.action,
    }); 
    
    const { data } = response;    
    

    if (data.status === "success") {

      data.inventorySummary.forEach(d=>{
        d.key = d.inv_auditor +"-" + d.date;
        
        const hours = parseInt(Math.abs(d.entry_time_end - d.entry_time_start) / (1000 * 60 * 60) % 24);
        const minutes = parseInt(Math.abs(d.entry_time_end - d.entry_time_start) / (1000 * 60) % 60);
        const seconds = parseInt(Math.abs(d.entry_time_end - d.entry_time_start) / (1000) % 60);
        d.time = hours + ":" + minutes + ":" + seconds;  

      })
      
      yield put(setDashboardInfo(data));
       yield put(
        setMessage({
          message: "Dashboard data received",
          variant: "info",
          isWorking: false,
        })
      );
    } else {
      console.log("no success");
       yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger"
        })
      ); 
    }
    yield put(setIndicator(false));
  } catch (error) {
    console.log("error", error);
    yield put(setIndicator(false));
     yield put(
      setMessage({
        message: "Settings data retrieval failed",
        variant: "warning"
      })
    ); 
  }
}



