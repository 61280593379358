import { createSlice } from "@reduxjs/toolkit";

const settingsSlice = createSlice({
  name: "settings",
  initialState: { settings: [] },
  reducers: {
    downloadToken(){},
    getSettings(state, action) {
      //console.log("getSettings",action);
    },
    setSettings(state, action) {
      //console.log("setSettings", action.payload);
      const settingsData = action.payload;

      return { ...state, settings: settingsData };
    },
    setSetting(state, action) {
      
      const settingData = action.payload;
      const settings = [
        ...state.settings.filter((d) => {
          if (d.id === settingData.id) {
            return false;
          } else {
            return true;
          }
        }),
        settingData,
      ]; 

      return { ...state, settings: settings };
    },
    updateSettings() {},
    updateToken(state, action) {
      const settings = [...state.settings];
      console.log("settings",settings.length);
      if (settings.length >0 ) {
        const settingsArray=[];
        const settings2 = {...settings[0],"full_access_token":action.payload.token};
        console.log(action.payload, settings2);
        settingsArray.push(settings2);
      
        return { ...state, settings: settingsArray };

      }
      
      //console.log(action.payload);

    },
  },
});

export const { downloadToken, getSettings, setSettings, setSetting, updateSettings,updateToken } = settingsSlice.actions;

export default settingsSlice.reducer;
