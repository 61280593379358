import { createSlice } from "@reduxjs/toolkit";

const buttonsSlice = createSlice({
  name: "buttons",
  initialState: { buttons: [] },
  reducers: {
   
    setButtons(state, action) {
      return { ...state, buttons: action.payload };
    }
  },
});

export const { setButtons} = buttonsSlice.actions;

export default buttonsSlice.reducer;
