/*!

*/
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// node.js library that concatenates classes (strings)

// javascipt plugin for creating charts

// react plugin used to create charts

import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";
// reactstrap components
import { Container, Card, CardHeader } from "reactstrap";

// core components

import Totals from "components/Ponce/Inventory/Totals";
import InventorySummary from "components/Ponce/Inventory/InventorySummary";

import CheckoutForm from "../stripe/CheckoutForm";

const stripePromise = loadStripe("pk_test_as5DCSXMyQFiNJq9EDK2FS8L");

function DashboardPonce() {
  const [clientSecret, setClientSecret] = useState("");

  /* useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("http://localhost/stripe/stripeAPI.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []); */

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <SimpleHeader name="Dashboard" parentName="Dashboards" />
      <Container className="mt--6" fluid>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}
      </Container>
    </>
  );
}

export default DashboardPonce;
//
