import { call, put } from "redux-saga/effects";
import { setSettings, setSetting, updateToken } from "../../slices/SettingsSlice";
import { requestGeneral} from "../requests/index";
import { setMessage } from "../../slices/MessageSlice";
import { setIndicator } from "redux/slices/IndicatorSlice.js";

export function* handleDownloadToken(action) {
  
  try {
     yield put(
      setMessage({
        message: "Downloading Token",
        variant: "info"
      })
    ); 
    yield put(setIndicator(true)); 

   

    const response = yield call(requestGeneral, {
      ...action.payload
    }); 
    
    const { data } = response;

    /*
    dispatch(
        updateSettings({
          url:
            "http://" +
            settings[0].ip_address +
            ":" +
            settings[0].port +
            "/updateSettings",
          action: "updateSettings",
          settings: temp2,
          method: "post",
        })
      );
      */

    //console.log(data);
    //yield(put(updateToken({token:data.fullJWT})))

    //const temp = {...action.payload, url:action.payload.settingsURL,token:data.fullJWT}
    //console.log(temp);

    
    

   
    //data.settings.current_customer_id = data.settings.current_customer_id * 1;
    
    

    if (data.status === "success") {
      const response2 = yield call(requestGeneral, {
        url:action.payload.settingsURL,
        token:data.fullJWT,
        method:"post"
        
      }); 
      //console.log(response2.data);
      //const { data2 } = response2;
      response2.data.data.forEach(d=>{
        d.current_customer_id = d.current_customer_id * 1;
        d.id = d.id *1;
  
      })
      yield put(setSettings(response2.data.data));
      
      //data.data.forEach(d=>{
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id *1;
  
      //})
     // yield put(setSettings(data.data));

       yield put(
        setMessage({
          message: "Settings data received",
          variant: "info"
        })
      ); 
    } else {
      //console.log("no success");
       yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger"
        })
      ); 
    }
    yield put(setIndicator(false)); 
  } catch (error) {
    console.log("error");
    console.log(error)
    yield put(
      setMessage({
        message: "Settings data retrieval failed",
        variant: "warning"
      })
    ); 
    yield put(setIndicator(false)); 
  }
}

export function* handleGetSettings(action) {
  
  try {
     yield put(
      setMessage({
        message: "Gettting settings...",
        variant: "info"
      })
    ); 
    yield put(setIndicator(true)); 

   /*  const response = yield call(requestGeneral, {
      url: "http://192.168.1.64/icp/icp.php",
      action: "getSettings",
    }); */

    /* yield put(
      setMessage({
        message: "Retrieveing settings",
        variant: "info",
        isWorking: true,
      })
    ); */

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      action: action.payload.action,
    }); 
    
    const { data } = response;
    //console.log(data);

   
    //data.settings.current_customer_id = data.settings.current_customer_id * 1;
    
    

    if (data.status === "success") {
      data.data.forEach(d=>{
        //d.current_customer_id = d.current_customer_id * 1;
        d.id = d.id *1;
  
      })
      yield put(setSettings(data.data));
       yield put(
        setMessage({
          message: "Settings data received",
          variant: "info"
        })
      ); 
    } else {
      //console.log("no success");
       yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger"
        })
      ); 
    }
    yield put(setIndicator(false)); 
  } catch (error) {
    console.log("error");
    yield put(
      setMessage({
        message: "Settings data retrieval failed",
        variant: "warning"
      })
    ); 
    yield put(setIndicator(false)); 
  }
}

export function* handleUpdateSettings(action) {
  //console.log("handleUpdateSettings");
  //console.log(action);
  try {
     yield put(
      setMessage({
        message: "Updating settings...",
        variant: "info",
      })
    );
    yield put(setIndicator(true)); 

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload
    });

   
    const { data } = response;
    //console.log(data)

   
    //data.settings.current_customer_id = data.settings.current_customer_id * 1;
    //data.settings.forEach(d=>{
    //  d.current_customer_id = d.current_customer_id * 1;

    //})
    

    if (data.status === "success") {
      //data.data.forEach(d=>{
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id *1;
  
      //})
      //yield put(setSetting(data.data));
      yield put(setSettings(data.data));
       yield put(
        setMessage({
          message: "Settings data updated",
          variant: "info"
        })
      ); 
    } else {
       yield put(
        setMessage({
          message: data.message + " error number:" + data.error +" "+ data,
          variant: "danger"
        })
      ); 
    }
    
    yield put(setIndicator(false)); 
    
  } catch (error) {
    console.log("error", error)
    yield put(
      setMessage({
        message: "Settings data retrieval failed",
        variant: "warning"
      })
    ); 
  }
}

