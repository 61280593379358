/*!


*/
import React, { useState } from "react";
import * as d3 from "d3";
import axios, { post } from "axios";

import { useSelector, useDispatch } from "react-redux";

import Buttons from "components/Ponce/Buttons";
import ItemMasterTableView from "components/Ponce/ItemMaster/ItemMasterTableView";
import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import {
  Card,
  FormGroup,
  Input,
  Button,
  Badge,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  Container,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import { setButtons } from "redux/slices/ButtonsSlice";
import QRCode from "qrcode.react";
import { setMessage } from "redux/slices/MessageSlice";
import { setIndicator } from "redux/slices/IndicatorSlice";
import { setItemMasterItems } from "redux/slices/ItemMasterSlice";
import { useRouteMatch } from "react-router-dom";
import { updateImportFileMapping } from "redux/slices/ImportFilesSlice";

//core components

function ImportFileMapping({ showLog }) {
  let fileName = useRouteMatch("/admin/importFile/:file").params.file;
  let buttonLabel = "";
  const tmp = fileName.split("|");
  fileName = tmp[0];
  if (tmp.length > 1) {
    buttonLabel = tmp[1];
  }

  //console.log("file", fileName, tmp, buttonLabel);

  const { ip } = useSelector((state) => state.ip);
  const [fieldMapping, setFieldMapping] = useState([]);
  const [needSorting, setNeedSortion] = useState(false);
  const [fieldMappingColumns, setFieldMappingColumns] = useState([
    {
      dataField: "file_name",
      text: "File",
      sort: true,
      csvType: "number",
      editable: false,
    },
  ]);

  const [dateFormats, setDateFormats] = useState([
    { formatting: "Select one" },
    { formatting: "YYYY-MM-DD" },
    { formatting: "YYYY-MM-DD HH:mm:ss" },
    { formatting: "YYYY-MM-DD HH:mm:ss Z" },
  ]);
  const [fileKeys, setFileKeys] = useState([]);
  const [fileInfo, setFileInfo] = useState({ fileName: "", fileType: "" });
  const [fileData, setFileData] = useState([]);

  const [fileDepartments, setFileDepartments] = useState(["0000", "Nothing"]);
  const [itemMasterFields, setItemMasterFields] = useState([
    {
      dataField: "item_id",
      fileKey: "",
      order: 0,
      text: "upc",
      sort: true,
      isNumeric: false,
      headerAlign: "left",
      align: "left",
      csvType: "number",
      default: "",
      field_from: -1,
    },
    {
      dataField: "itemDescription",
      fileKey: "",
      order: 1,
      text: "description",
      sort: true,
      isNumeric: false,
      headerAlign: "left",
      align: "left",
      csvType: "number",
      default: "",
      field_from: -1,
    },
    {
      dataField: "quantityOnHand",
      fileKey: "",
      order: 2,
      text: "quantity_on_hand",
      sort: true,
      isNumeric: true,
      headerAlign: "right",
      align: "righ",
      csvType: "number",
      default: 0,
      field_from: -1,
    },
    {
      dataField: "itemPrice",
      fileKey: "",
      order: 3,
      text: "retail",
      sort: true,
      isNumeric: true,
      headerAlign: "right",
      align: "right",
      csvType: "number",
      default: 0,
      field_from: -1,
    },
    {
      dataField: "department",
      fileKey: "",
      order: 4,
      text: "department",
      sort: true,
      isNumeric: false,
      headerAlign: "left",
      align: "left",
      csvType: "number",
      default: "",
      field_from: -1,
    },
    {
      dataField: "departmentDescription",
      fileKey: "",
      order: 5,
      text: "department_description",
      sort: true,
      isNumeric: false,
      headerAlign: "left",
      align: "left",
      csvType: "number",
      default: "",
      field_from: -1,
    },
    {
      dataField: "sku",
      fileKey: "",
      order: 6,
      text: "sku",
      sort: true,
      isNumeric: false,
      headerAlign: "left",
      align: "left",
      csvType: "number",
      default: "",
      field_from: -1,
    },
    /* {
        "dataField": "sku",
        "fileKey":"",
        "order":6,
        "text": "sku",
        "sort": true,
        "isNumeric":false,
        "headerAlign": "left",
        "align": "left",
        "csvType": "number",
        "default":""
    },
    {
        "dataField": "cost",
        "fileKey":"",
        "text": "cost",
        "order":7,
        "sort": true,
        "isNumeric":true,
        "headerAlign": "right",
        "align": "right",
        "csvType": "number",
        "default":0
    },
    {
        "dataField": "single_count",
        "fileKey":"",
        "order":8,
        "text": "single_count",
        "sort": true,
        "isNumeric":true,
        "headerAlign": "right",
        "align": "right",
        "csvType": "number",
        "default":1
    },
    {
        "dataField": "single_upc",
        "fileKey":"",
        "order":9,
        "text": "single_upc",
        "sort": true,
        "isNumeric":false,
        "headerAlign": "left",
        "align": "left",
        "csvType": "number",
        "default":""
    } */
  ]);

  //console.log(itemMasterFields);
  //console.log(fieldMapping);

  /*
  
*/

  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  const { customers } = useSelector((state) => {
    return state.customers;
  });
  const currentCustomer = customers.filter((d) => {
    return d.selected !== null;
  });

  const dispatch = useDispatch();

  React.useEffect(() => {
    const temp =
      d3.csvParse(`upc,description,quantity_on_hand,retail,department,department_description,sku,cost,single_count,single_upc
      000000100014,40SQ FT(30X16)TRADITIO,0,0.01,084,Department 084,407477,0,0,
      000000301008,30IN 100SQ FT CHRISTMA,0,0.01,084,Department 084,407478,0,0,
      000000400404,40 SQ FT 40IN XMAS WRA,0,0.01,084,Department 084,474444,0,0,
      000000400411,40IN CHRISTMAS GIFTWRA,0,0.01,084,Department 084,355575,0,0,
      000000461917,flexSPCHERIS FEUR DELS,3,1,017,Department 017,487130,0,0,
      000001976892,ASSORTED THROW PILLOW ,2,4.99,017,Department 017,197689,0,0,
      000002621975,4.99 PET BED ,4,4.99,019,Department 019,262197,0,0,
      000004606321,CO COVER GIRL NAIL POL,0,1,093,Department 093,645483,0,0,
      000005076512,STEVENS SMK TURKEY DRU,0,2.59,074,Department 074,394377,0,0,
      012000103131,Mocha 15 Pk,0,25.9,074,Department 074,,0,15,012000004100`);
    //console.log(temp.length, temp[0]);
    const keys = Object.keys(temp[0]);
    //console.log(keys);
    const cols = [];
    for (let i = 0; i < keys.length; i++) {
      const a = isRegProperty(temp[0][keys[i]]) === "number" ? "right" : "left";
      const c = {
        dataField: keys[i],
        text: keys[i],
        sort: true,
        headerAlign: a,

        align: a,
        csvType: "number",
      };
      cols.push(c);
    }
    //console.log(cols);
  }, []);

  React.useEffect(() => {
    getData();
  }, [ip]);

  React.useEffect(() => {
    //buttonLabel.length > 0 && buttonLabel === "Create" && getColumns();
  }, [ip, buttonLabel]);

  React.useEffect(() => {
    currentCustomer.length === 1 &&
      dispatch(setButtons(currentCustomer[0].button_types));
  }, [currentCustomer.length, dispatch]);

  function editableValue(content, row, rowIndex, columnIndex) {
    //console.log("row", row);
    let returnValue = true;
    if (row.name === "inv_quantity") {
      returnValue = true;
    } else {
      returnValue = false;
    }

    return returnValue;
  }
  function editableFormatting(content, row, rowIndex, columnIndex) {
    //console.log("row", row);
    let returnValue = true;
    if (row.name === "formatting") {
      returnValue = true;
    } else {
      returnValue = false;
    }

    return returnValue;
  }

  function editableSequence(content, row, rowIndex, columnIndex) {
    //console.log("row", row);
    let returnValue = true;
    //if (row.name === "formatting") {
    //returnValue = true;
    //} else {
    //returnValue = false;
    //}

    return returnValue;
  }

  function getData() {
    //console.log("get data", ip);
    dispatch(setIndicator(true));
    ip.length > 0 &&
      fileName.length > 0 &&
      axios
        .get(
          "http://" +
            ip[0].ip +
            ":" +
            ip[0].websocket_port +
            "/importFileMapping/" +
            fileName
        )
        .then((response) => {
          dispatch(
            setMessage({
              message: "Fields mapping retrieved",
              variant: "info",
            })
          );

          //console.log(response);
          response.data.data.forEach((d) => {
            //d.customerIDStore = "" + d.id + "-" + d.store_number;
            //d.key = "" + d.customer_id + d.store_number;
          });
          //console.log(response);
          if (response.data.data.length > 0) {
            updateColumns(response.data.data[0]);
            setFieldMapping(response.data.data);

            //setCustomers(response.data.data);
            //updateColumns(response.data.data[0]);
          }
          //setLocationsTable(response.data.data);
          //setLocationsTable(prevState => {
          //  return [...response.data.data]
          //
          //;
          //});
          dispatch(setIndicator(false));
          //callback(response.data);
        })
        .catch((error) => {
          dispatch(setIndicator(false));
          dispatch(setMessage({ message: error, variant: "danger" }));
          console.log(error);
          //console.log("*****  "+error)
        });
  }

  function handleFile(e) {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      //console.log(file);
      setFileInfo({ fileName: file.name, fileType: file.type });
      var reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = function (evt) {
        //console.log("good");
        const temp = d3.csvParse(evt.target.result);
        setFileData(temp);
        //console.log(temp.length, temp[0]);
        const keys = Object.keys(temp[0]);
        //console.log(keys);
        //console.log(temp[0]["Item Cost"]);
        const data = [];
        const cols = [];
        for (let i = 0; i < keys.length; i++) {
          const c = {
            dataField: keys[i],
            order: i,
          };
          cols.push(c);
        }
        setFileKeys(cols);

        //console.log("cols", cols);

        for (let i = 0; i < temp.length; i++) {
          const dataTemp = {};
          //console.log(temp[i]);
          for (let j = 0; j < itemMasterFields.length; j++) {
            if (itemMasterFields[j].fileKey === "") {
              dataTemp[itemMasterFields[j].dataField] =
                itemMasterFields[j].default;
            } else {
              if (itemMasterFields[j].isNumeric) {
                dataTemp[itemMasterFields[j].dataField] =
                  temp[i][itemMasterFields[j].fileKey] * 1;
              } else {
                dataTemp[itemMasterFields[j].dataField] =
                  temp[i][itemMasterFields[j].fileKey];
              }
            }

            //dataTemp[itemMasterFields.dataField] =
          }
          data.push(dataTemp);
        }
        //console.log(data);

        //document.getElementById("fileContents").innerHTML = evt.target.result;
      };
      reader.onerror = function (evt) {
        console.log("error");
        //document.getElementById("fileContents").innerHTML = "error reading file";
      };
      //if((!!file.name.match(/(\.|\/)(csv)$/i)) || (!!file.name.match(/(\.|\/)(txt)$/i)) ){
      //if((!!file.name.match(/(item_master\.|\/)(csv)$/i))){
      /* if (file.name.includes("ITEM_MASTER") && file.name.includes(".CSV")) {
          console.log("handlefile " + file.name);
          let formData = new FormData();
          formData.append("file", e.target.files[0]);
          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };
          dispatch(setIndicator(true));

          //post("http://localhost:4001/customFile", formData,config)

         
        } else {
          dispatch(
            setMessage({
              message: "Only ITEM_MASTER.CSV file is accepted",
              variant: "danger",
            })
          );
          console.log("file does not match");
        } */
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    //console.log(name, value);
    var tmp = event.target.value.split("|");
    //console.log(tmp);
    setItemMasterFields((prevState) => {
      const currColumn = prevState.filter((d) => d.dataField === name);
      currColumn[0].fileKey = value === "-1" ? "" : tmp[1];
      currColumn[0].field_from = value === "-1" ? -1 : tmp[0] * 1;
      //console.log(currColumn);
      //console.log(prevState);
      const columns = [
        ...prevState.filter((d) => {
          if (d.dataField === name) {
            return false;
          } else {
            return true;
          }
        }),
        currColumn[0],
      ];
      columns.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
      //console.log(currColumn);
      const currMapping = fieldMapping.filter((d) => {
        return d.field_to === currColumn[0].dataField;
      });
      //console.log(currMapping);
      if (currMapping.length > 0) {
        currMapping[0].field_from = currColumn[0].field_from;
      } else {
        const tempMapping = {
          file_name: fileName,
          field_from: currColumn[0].field_from,
          field_to: currColumn[0].dataField,
          is_numeric: currColumn[0].isNumeric ? 1 : 0,
        };
        currMapping.push(tempMapping);
      }

      const currentMappings = [
        ...fieldMapping.filter((d) => {
          if (d.field_to === currColumn[0].dataField) {
            return false;
          } else {
            return true;
          }
        }),
        currMapping[0],
      ];
      //console.log(currMapping);
      updateColumns(currMapping[0]);
      setFieldMapping(currentMappings);

      return columns;
      //return [...response.data.data]

      //;
    });
    /*
      const columnData = action.payload;
      const columns = [
        ...state.columns.filter((d) => {
          if (d.id === columnData.id) {
            return false;
          } else {
            return true;
          }
        }),
        columnData,
      ]; 

      return { ...state, columns: columns };
      */

    //const {name, value} = event.target;
    //setInfo(prevValue =>{
    //return {
    //...prevValue,
    //[name]:value
    //}
    //})
  }
  function handleClick(event) {
    //console.log("handleClick");
    dispatch(
      updateImportFileMapping({
        action: "updateImportFileMapping",
        method: "post",
        url:
          "http://" +
          ip[0].ip +
          ":" +
          ip[0].websocket_port +
          "/updateImportFileMapping",
        data: fieldMapping.filter((d) => {
          return d.field_from >= 0;
        }),
      })
    );
    //console.log(fileData);
    const data = [];
    for (let i = 0; i < fileData.length; i++) {
      const dataTemp = {};
      //console.log(fileData[i]);
      for (let j = 0; j < itemMasterFields.length; j++) {
        if (itemMasterFields[j].fileKey === "") {
          dataTemp[itemMasterFields[j].dataField] = itemMasterFields[j].default;
        } else {
          if (itemMasterFields[j].isNumeric) {
            dataTemp[itemMasterFields[j].dataField] =
              fileData[i][itemMasterFields[j].fileKey] * 1;
          } else {
            dataTemp[itemMasterFields[j].dataField] =
              fileData[i][itemMasterFields[j].fileKey];
          }
        }

        //dataTemp[itemMasterFields.dataField] =
      }
      if (dataTemp.item_id.length !== 0) {
        data.push(dataTemp);
      }
    }
    //yield put(setItemMasterItems({file_name:action.payload.fileName,file_type: action.payload.fileType,items:response}));

    if (
      data.length > 0 &&
      data[0].departmentDescription.length > 0 &&
      data[0].department === ""
    ) {
      const depts = ["Empty"];
      //console.log("missing departments");
      data.forEach((d) => {
        //console.log(d);
        //const dept = depts.filter()
        //console.log(d.department_description);
        let index = depts.indexOf(d.departmentDescription);

        //let index = depts.findIndex(d.department_description);
        if (index >= 0) {
          d.department = "" + index;
          d.department = d.department.padStart(4, "0");
        } else {
          depts.push(d.departmentDescription);
          index = depts.indexOf(d.departmentDescription);
          d.department = "" + index;
          d.department = d.department.padStart(4, "0");
        }
        d.upc = d.item_id;

        //console.log(index);
        //console.log(array1.findIndex(isLargeNumber));
      });
    }

    dispatch(
      setItemMasterItems({
        file_name: fileInfo.fileName,
        file_type: fileInfo.fileType,
        items: data,
      })
    );
    //console.log(data);
    //console.log(itemMasterFields);
  }

  function isRegProperty(obj) {
    var t = typeof obj;
    return t;
  }

  function updateColumns(obj) {
    const cols = [];
    const keys = Object.keys(obj);
    //console.log(keys);
    for (let i = 0; i < keys.length; i++) {
      const a = isRegProperty(obj[keys[i]]) === "number" ? "right" : "left";
      //console.log(keys[i]);
      const c = {
        dataField: keys[i],
        text: keys[i],
        sort: true,
        headerAlign: a,
        align: a,
        editable: false,
        csvType: "number",
      };
      cols.push(c);
    }
    //setColumns(cols);
    setFieldMappingColumns(cols);
    //console.log(cols);
    //
  }

  return (
    <>
      <SimpleHeader name="" parentName="" />
      <Container className="mt--6" fluid>
        <Card>
          {/* <CardHeader>
                <h3 className="mb-0">React Bootstrap Table 2</h3>
                <p className="text-sm mb-0">
                  This is an exmaple of data table using the well known
                  react-bootstrap-table2 plugin. This is a minimal setup in
                  order to get started fast.
                </p>
              </CardHeader> */}
          {fieldMapping.length > 0 && (
            <ToolkitProvider
              data={fieldMapping}
              key="field_to"
              keyField="field_to"
              columns={fieldMappingColumns}
              search
              exportCSV={{
                fileName: "ITEM_MASTER.CSV",
                separator: ",",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div className="py-4 table-responsive">
                  {
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <label>
                        Search:
                        <SearchBar
                          className="form-control-sm"
                          placeholder=""
                          {...props.searchProps}
                        />
                      </label>
                      <br />

                      {
                        <ExportCSVButton {...props.csvProps}>
                          Export CSV!!
                        </ExportCSVButton>
                      }
                    </div>
                  }

                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    striped
                    cellEdit={cellEditFactory({
                      mode: "click",
                      blurToSave: true,
                      beforeSaveCell(oldValue, newValue, row, column, done) {
                        const newRow = {
                          ...row,
                          [column.dataField]: newValue,
                        };

                        console.log("newRow", newRow);
                        //console.log(setupCustomerLocations)
                        //let temp = [...setupCustomerLocations,newRow];
                        //console.log(temp);
                        //dispatch(setCustomer(newRow));
                        if (ip.length === 1) {
                          /*
                                dispatch(
                                  update({
                                    url:
                                      "http://" +
                                      ip[0].ip +
                                      ":" +
                                      ip[0].websocket_port +
                                      "/updateCustomerLocation",
                                    action: "updateCustomerLocation",
                                    data: newRow,
                                    method: "post",
                                  })
                                );
                                */
                        }

                        done(false); // The changes are handled by the dispatch

                        return { async: true };
                      },
                    })}
                  />
                </div>
              )}
            </ToolkitProvider>
          )}
        </Card>

        {buttonLabel === "Load" && (
          <Card className="card-stats">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Item Master</small>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form>
                <div className="custom-file">
                  <input
                    className="custom-file-input"
                    id="customFileLang"
                    lang="en"
                    type="file"
                    onChange={handleFile}
                  />

                  <label className="custom-file-label" htmlFor="customFileLang">
                    Select file
                  </label>
                </div>
              </Form>
              <h1 className="text-center mb-5">Mapping Fields</h1>
              <ListGroup className="list my--3" flush>
                <ListGroupItem className="px-0 active ">
                  <Row className="align-items-center">
                    <Col className="col-auto pl-4">
                      ITEM_MASTER.CSV field/order
                    </Col>
                    <div className="col ml--2"></div>
                    <Col className="col-auto pr-4">Your file Fields</Col>
                  </Row>
                </ListGroupItem>
                {itemMasterFields.map((d) => (
                  <ListGroupItem className="px-0" key={d.order}>
                    <Row className="align-items-center">
                      <Col className="col-auto  pl-4"></Col>
                      <div className="col ml--2">
                        <span className="text-success">{d.order + 1}</span>
                        <h4 className="mb-0">{d.dataField}</h4>

                        <small>{d.isNumeric ? "Numeric" : "Non-Numeric"}</small>
                      </div>
                      <Col className="col-auto  pr-4">
                        <Input
                          type="select"
                          onChange={handleChange}
                          id={d.order}
                          name={d.dataField}
                        >
                          <option value="-1">Select field</option>
                          {fileKeys.map((d0) => (
                            <option
                              key={d0.order}
                              value={d0.order + "|" + d0.dataField}
                            >
                              {d0.dataField}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </CardBody>
            <CardFooter>
              <Button color="info" outline block onClick={handleClick}>
                Show Data
              </Button>
            </CardFooter>
          </Card>
        )}
      </Container>

      {/* <div className="content">
        <Card>
          <Buttons pageName={"itemMaster"} />
        </Card>
      </div> */}

      <ItemMasterTableView />
    </>
  );
}

export default ImportFileMapping;
