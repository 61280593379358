import { createSlice } from "@reduxjs/toolkit";

const importFilesSlice = createSlice({
  name: "importFiles",
  initialState: { importFiles: [] },
  reducers: {
    addImportFiles() {},
    deleteImportFile() {},
    getImportFiles(state, action) {
      //console.log("getCustomers",action);
    },
    loadImportFiles() {},
    setImportFiles(state, action) {
      //console.log("setCustomers", action.payload);
      const data = action.payload;

      return { ...state, importFiles: data };
    },
    setImportFile(state, action) {
      const data = action.payload;
      //console.log(state, data);
      const ImportFiles = [
        ...state.importfiles.filter((d) => {
          if (d.id === data.id) {
            return false;
          } else {
            return true;
          }
        }),
        data,
      ];

      return { ...state, importfiles: ImportFiles };
    },
    updateImportFile() {},
    updateImportFileMapping() {},
    updateExportFileMapping() {},
  },
});

export const {
  addImportFiles,
  deleteImportFile,
  getImportFiles,
  loadImportFiles,
  setImportFiles,
  setImportFile,
  updateImportFile,
  updateImportFileMapping,
  updateExportFileMapping,
} = importFilesSlice.actions;

export default importFilesSlice.reducer;
