import { createSlice } from "@reduxjs/toolkit";

const messageSlice = createSlice({
  name: "message",
  initialState: {
    message: "test",
    variant: "success"
  },
  reducers: {
      setMessage : (state, action) => {
       
        return  {...state, ...action.payload};
      }
  }
});

export const { setMessage } = messageSlice.actions;

export default messageSlice.reducer;
