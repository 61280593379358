import { take, takeLatest } from "redux-saga/effects";

// Handlers
import { handleGetApps } from "./handlers/AppsHandler";
import {
  handleAddImportFiles,
  handleDeleteImportFile,
  handleGetImportFiles,
  handleLoadImportFiles,
  handleUpdateExportFileMapping,
  handleUpdateImportFile,
  handleUpdateImportFileMapping,
} from "./handlers/ImportFilesHandler";
import {
  handleAddAreas,
  handleDeleteArea,
  handleGetAreas,
  handleLoadAreasFile,
  handleUpdateArea,
} from "./handlers/SetupAreasHandler";
import {
  handleAddCustomerLocation,
  handleDeleteCustomerLocation,
  handleGetCustomerLocations,
  handleLoadCustomerLocationsFile,
  handleUpdateCustomerLocation,
} from "./handlers/SetupCustomerLocationsHandler";
import {
  handleAddCustomers,
  handleDeleteCustomer,
  handleGetCustomers,
  handleLoadCustomerFile,
  handleUpdateCustomer,
} from "./handlers/SetupCustomersHandler";
import {
  handleAddDepartments,
  handleDeleteDepartment,
  handleGetDepartments,
  handleLoadDepartmentsFile,
  handleUpdateDepartment,
} from "./handlers/SetupDepartmentsHandler";
import {
  handleAddSections,
  handleDeleteSection,
  handleGetSections,
  handleLoadSectionFile,
  handleUpdateSection,
} from "./handlers/SetupSectionsHandler";
import {
  handleGetRetailFinancialInventory,
  handleUpdateFinancialInventory,
} from "./handlers/RetailFinancialInventoryHandler";
import {
  handleCreateFile,
  handleGetRetailInventory,
  handleProcessInventory,
  handleUpdateInventory,
} from "./handlers/RetailInventoryHandler";
import {
  handleLoadItemMasterItems,
  handleViewItemMasterItems,
} from "./handlers/ItemMasterHandler";
import {
  handleDownloadToken,
  handleGetSettings,
  handleUpdateSettings,
} from "./handlers/SettingsHandler";
//import { handleGetCustomers } from "./handlers/CustomersHandler";
import {
  handleGetColumns,
  handleUpdateColumn,
} from "./handlers/ColumnsHandler";
import { handleGetDashboardInfo } from "./handlers/DashboardHandler";
import { handleViewInventoryOutput } from "./handlers/InventoryOutputHandler";
import { handleViewIP } from "./handlers/IPHandler";

// Slices

import { getApps } from "redux/slices/AppsSlice";
import {
  addImportFiles,
  deleteImportFile,
  getImportFiles,
  loadImportFiles,
  updateExportFileMapping,
  updateImportFile,
  updateImportFileMapping,
} from "redux/slices/ImportFilesSlice";
import {
  addAreas,
  deleteArea,
  getAreas,
  loadAreasFile,
  updateArea,
} from "redux/slices/SetupAreasSlice";
import {
  addCustomerLocation,
  deleteCustomerLocation,
  getCustomerLocations,
  loadCustomerLocationsFile,
  updateCustomerLocation,
} from "redux/slices/SetupCustomerLocationsSlice";
import {
  addCustomers,
  deleteCustomer,
  getCustomers,
  loadCustomersFile,
  updateCustomer,
} from "redux/slices/SetupCustomersSlice";
import {
  addDepartments,
  deleteDepartment,
  getDepartments,
  loadDepartmentsFile,
  updateDepartment,
} from "redux/slices/SetupDepartmentsSlice";
import {
  addSections,
  deleteSection,
  getSections,
  loadSectionsFile,
  updateSection,
} from "redux/slices/SetupSectionsSlice";
import {
  getRetailFinancialInventory,
  updateFinancialInventory,
} from "redux/slices/RetailFinancialInventorySlice";
import {
  createFile,
  getRetailInventory,
  processInventory,
  updateInventory,
} from "redux/slices/RetailInventorySlice";

import {
  downloadToken,
  getSettings,
  updateSettings,
} from "../slices/SettingsSlice";
//import { getCustomers } from "../slices/CustomersSlice.js";

import { getColumns, updateColumn } from "redux/slices/ColumnsSlice";
import { getDashboardInfo } from "redux/slices/DashboardSlice";
import {
  loadItemMasterItems,
  viewItemMasterItems,
} from "../slices/ItemMasterSlice";
import { viewInventoryOutput } from "redux/slices/InventoryOutputSlice";
import { viewIP } from "../slices/IPSlice";

export function* watcherSaga() {
  yield takeLatest(addAreas.type, handleAddAreas);
  yield takeLatest(addCustomerLocation.type, handleAddCustomerLocation);
  yield takeLatest(addCustomers.type, handleAddCustomers);
  yield takeLatest(addDepartments.type, handleAddDepartments);
  yield takeLatest(addImportFiles.type, handleAddImportFiles);
  yield takeLatest(addSections.type, handleAddSections);
  yield takeLatest(createFile.type, handleCreateFile);
  yield takeLatest(deleteArea.type, handleDeleteArea);
  yield takeLatest(deleteCustomer.type, handleDeleteCustomer);
  yield takeLatest(deleteCustomerLocation.type, handleDeleteCustomerLocation);
  yield takeLatest(deleteDepartment.type, handleDeleteDepartment);
  yield takeLatest(deleteImportFile.type, handleDeleteImportFile);
  yield takeLatest(deleteSection.type, handleDeleteSection);
  yield takeLatest(downloadToken.type, handleDownloadToken);
  yield takeLatest(getApps.type, handleGetApps);
  yield takeLatest(getAreas.type, handleGetAreas);
  yield takeLatest(getColumns.type, handleGetColumns);
  yield takeLatest(getCustomerLocations.type, handleGetCustomerLocations);
  yield takeLatest(getCustomers.type, handleGetCustomers);
  yield takeLatest(getDashboardInfo, handleGetDashboardInfo);
  yield takeLatest(getDepartments.type, handleGetDepartments);
  yield takeLatest(getImportFiles.type, handleGetImportFiles);
  yield takeLatest(
    getRetailFinancialInventory.type,
    handleGetRetailFinancialInventory
  );
  yield takeLatest(getRetailInventory.type, handleGetRetailInventory);
  yield takeLatest(getSections.type, handleGetSections);
  yield takeLatest(getSettings.type, handleGetSettings);
  yield takeLatest(loadAreasFile.type, handleLoadAreasFile);
  yield takeLatest(
    loadCustomerLocationsFile.type,
    handleLoadCustomerLocationsFile
  );
  yield takeLatest(loadCustomersFile.type, handleLoadCustomerFile);
  yield takeLatest(loadDepartmentsFile.type, handleLoadDepartmentsFile);
  yield takeLatest(loadImportFiles.type, handleLoadImportFiles);
  yield takeLatest(loadItemMasterItems.type, handleLoadItemMasterItems);
  yield takeLatest(loadSectionsFile.type, handleLoadSectionFile);
  yield takeLatest(processInventory.type, handleProcessInventory);
  yield takeLatest(updateArea.type, handleUpdateArea);
  yield takeLatest(updateColumn.type, handleUpdateColumn);
  yield takeLatest(updateCustomer.type, handleUpdateCustomer);
  yield takeLatest(updateCustomerLocation.type, handleUpdateCustomerLocation);
  yield takeLatest(updateDepartment.type, handleUpdateDepartment);
  yield takeLatest(
    updateFinancialInventory.type,
    handleUpdateFinancialInventory
  );
  yield takeLatest(updateExportFileMapping.type, handleUpdateExportFileMapping);
  yield takeLatest(updateImportFile.type, handleUpdateImportFile);
  yield takeLatest(updateImportFileMapping.type, handleUpdateImportFileMapping);
  yield takeLatest(updateInventory.type, handleUpdateInventory);
  yield takeLatest(updateSection.type, handleUpdateSection);
  yield takeLatest(updateSettings.type, handleUpdateSettings);
  yield takeLatest(viewInventoryOutput.type, handleViewInventoryOutput);
  yield takeLatest(viewIP.type, handleViewIP);
  yield takeLatest(viewItemMasterItems.type, handleViewItemMasterItems);
}
