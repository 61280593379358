/*!

*/
import React, { useEffect, useState } from "react";

import { useRouteMatch } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { getApps } from "redux/slices/AppsSlice";
// node.js library that concatenates classes (strings)

// javascipt plugin for creating charts

// react plugin used to create charts

import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Alert,
  Row,
  Col,
  CardImg,
  CardHeader,
  Button,
  Badge,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

// core components
//http://localhost:3003/auth/complete
//?payment_intent=pi_3MmnNwKmzCj9kCEq0f3FfDYr
//&payment_intent_client_secret=pi_3MmnNwKmzCj9kCEq0f3FfDYr_secret_Y60SGVNDzxNujqAmPvOSlghjp
//&redirect_status=succeeded

function ThankYou() {
  const dispatch = useDispatch();
  const { ip } = useSelector((state) => state.ip);
  const { apps } = useSelector((state) => state.apps);
  const [app, setApp] = useState({});
  const [paymentIntent, setPaymentIntent] = useState("");
  const [intent, setIntent] = useState("");
  const [failedMessage, setFailedMessage] = useState("");

  let appID = useRouteMatch("/auth/complete/:id").params.id * 1;

  //console.log("Thank you", intent, apps);

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    const paymentIntent = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );

    if (!clientSecret) {
      return;
    }
    if (!paymentIntent) {
      return;
    }
    setPaymentIntent(paymentIntent);
    //console.log(clientSecret, paymentIntent);
  }, []);

  React.useEffect(() => {
    ip.length === 1 &&
      dispatch(
        getApps({
          action: "getApps",
          url: ip[0].ip + "/stripe/dbAPI.php",
          page: "home",
        })
      );
  }, [dispatch, ip]);

  React.useEffect(() => {
    if (apps.length > 0) {
      const tempApp = apps.filter((d) => {
        return d.id === appID;
      });
      if (tempApp.length === 1) {
        setApp(tempApp[0]);
      }
      //console.log(tempApp);
    }
  }, [appID, apps]);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    //http://localhost/stripe/stripeAPI.php
    //console.log("app id", appID);
    ip.length === 1 &&
      paymentIntent &&
      fetch(ip[0].ip + "/stripe/stripeAPI.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          action: "retrieveIntent",
          appID: appID,
          intent: paymentIntent,
        }),
      })
        .then((res) => {
          //console.log(res);
          //console.log(res.json());
          return res.json();
        })
        .then((data) => {
          data.status === "success" && setIntent(data.intent);
          data.status !== "success" && setFailedMessage(data.message);
          console.log(data);
        });
  }, [ip, paymentIntent]);

  return (
    <>
      <SimpleHeader name="Dashboard" parentName="Dashboards" />
      <Container className="mt--4" fluid>
        <Card>
          <CardBody>

            <br />
            {intent && (
              <>
                <Alert color="success">
                  <strong>Success!</strong> Thank you for purchasing the
                  inventory app token!
                </Alert>
                <Alert color="info">
                  <strong>
                    On your Android inventory app, go to settings, in the email,
                    enter the email you used to purchase the token.
                    <br />
                    Tap Save Settings
                    <br />
                    Click the Download Purchased Token button
                  </strong>
                </Alert>
                <Alert color="info">
                  <strong>
                    Please do not hesitate to ask any questions you may have
                  </strong>
                </Alert>
              </>
            )}
            {failedMessage && (
              <>
                <Alert color="warning">
                  <strong>Failed</strong> {failedMessage}
                </Alert>
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default ThankYou;
//
