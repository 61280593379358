import { createSlice } from "@reduxjs/toolkit";

const setupCustomersSlice = createSlice({
  name: "setupCustomers",
  initialState: { setupCustomers: [] },
  reducers: {
    addCustomers() {},
    deleteCustomer() {},
    getCustomers(state, action) {
      //console.log("getCustomers",action);
    },
    loadCustomersFile(){},
    setCustomers(state, action) {
      //console.log("setCustomers", action.payload);
      const data = action.payload;

      return { ...state, setupCustomers: data };
    },
    setCustomer(state, action) {
      
      const data = action.payload;
      //console.log(state, data);
      const Customers = [
        ...state.setupCustomers.filter((d) => {
          if (d.id === data.id) {
            return false;
          } else {
            return true;
          }
        }),
        data,
      ]; 

      return { ...state, setupCustomers: Customers };
    },
    updateCustomer() {},
  },
});

export const { addCustomers, deleteCustomer, getCustomers,loadCustomersFile, setCustomers, setCustomer, updateCustomer } = setupCustomersSlice.actions;

export default setupCustomersSlice.reducer;
