import { createSlice } from "@reduxjs/toolkit";

const ipSlice = createSlice({
  name: "ip",
  //initialState: { ip:[{id:0,ip:"99.99.99.99",websocket_port:0}]},
  initialState: { ip: [] },
  reducers: {
    loadIP() {},

    setIP(state, action) {
      //console.log("setIP", action);
      const ipData = action.payload;
      //ipData.websocket_port = ipData.websocket_port * 1;
      return { ...state, ip: ipData };
    },
    updateIP() {},
    viewIP() {},
  },
});

export const { loadIP, setIP, updateIP, viewIP } = ipSlice.actions;

export default ipSlice.reducer;
