import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboardInfo",
  initialState: { dashboardInfo: [],
                  dashboardAreaSummary:[],
                  dashboardSectionSummary:[],
                  dashboardAuditorSummary:[],
                  dashboardFileSummary:[],
                  dashboardInventorySummary:[] },
  reducers: {
    getDashboardInfo(state, action) {
      //console.log("getSettings",action);
    },
    setDashboardInfo(state, action) {
      
      //console.log(action.payload);
      return { ...state, dashboardInfo: action.payload.totals,
                         dashboardAreaSummary:action.payload.areas,
                         dashboardSectionSummary:action.payload.sections,
                         dashboardAuditorSummary:action.payload.auditors,
                         dashboardFileSummary:action.payload.files,
                         dashboardInventorySummary:action.payload.inventorySummary };
    },
    
    
  },
});

export const { getDashboardInfo, setDashboardInfo } = dashboardSlice.actions;

export default dashboardSlice.reducer;
