import { call, put } from "redux-saga/effects";
import { requestGeneral } from "../requests/index";
import { setMessage } from "../../slices/MessageSlice";
import { setIndicator } from "redux/slices/IndicatorSlice.js";
import { setImportFiles, setImportFile } from "redux/slices/ImportFilesSlice";

export function* handleAddImportFiles(action) {
  //console.log("handleAddCustomerLocations");

  try {
    yield put(
      setMessage({
        message: "Adding Import Files...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload,
    });

    const { data } = response;
    //console.log("handleAddCustomerLocations",data);
    if (data.status === "success") {
      data.data.forEach((d) => {
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id *1;
      });
      yield put(setImportFiles(data.data));
      yield put(
        setMessage({
          message: "Import Files data received",
          variant: "info",
        })
      );
    } else {
      //console.log("no success");
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger",
        })
      );
    }
    yield put(setIndicator(false));
  } catch (error) {
    console.log("error");
    yield put(
      setMessage({
        message: "Import Files data retrieval failed",
        variant: "warning",
      })
    );
    yield put(setIndicator(false));
  }
}

export function* handleDeleteImportFile(action) {
  console.log("handleDeleteImportFile");

  try {
    yield put(
      setMessage({
        message: "Deleting Import File...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload,
    });

    const { data } = response;
    //console.log("handleDeleteCustomer",data);
    if (data.status === "success") {
      data.data.forEach((d) => {
        d.key = "" + d.button_label + d.file_name + d.page;
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id *1;
      });
      yield put(setImportFiles(data.data));
      yield put(
        setMessage({
          message: "Import Files data received",
          variant: "info",
        })
      );
    } else {
      //console.log("no success");
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger",
        })
      );
    }
    yield put(setIndicator(false));
  } catch (error) {
    console.log("error");
    yield put(
      setMessage({
        message: "Customer data retrieval failed",
        variant: "warning",
      })
    );
    yield put(setIndicator(false));
  }
}

export function* handleGetImportFiles(action) {
  //console.log("handleGetCustomerLocations");

  try {
    yield put(
      setMessage({
        message: "Gettting Import Files...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload,
    });

    const { data } = response;
    console.log("handleGetImportFiles", data);
    if (data.status === "success") {
      data.data.forEach((d) => {
        d.key = "" + d.button_label + d.file_name + d.page;
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id *1;
      });
      yield put(setImportFiles(data.data));
      yield put(
        setMessage({
          message: "Import Files data received",
          variant: "info",
        })
      );
    } else {
      //console.log("no success");
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger",
        })
      );
    }
    yield put(setIndicator(false));
  } catch (error) {
    console.log("error");
    yield put(
      setMessage({
        message: "Import Files data retrieval failed",
        variant: "warning",
      })
    );
    yield put(setIndicator(false));
  }
}

export function* handleLoadImportFiles(action) {
  console.log("handleLoadImportFiles");
  console.log(action);
  try {
    yield put(setIndicator(true));

    /* yield put(
      setMessage({
        message: "Gettting item Master...",
        variant: "info",
        
      })
    ); */
    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload,
    });

    //console.log("handleLoadAreasFile response",response);
    const { data } = response;
    //console.log(JSON.parse(JSON.stringify(data)));
    console.log(data);
    if (data.status === "success") {
      data.data.forEach((d) => {
        //d.key = ""+d.customer_id + d.store_number;
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id *1;
      });
      yield put(setImportFiles(data.data));
      yield put(
        setMessage({
          message: "Import Files data received ",
          variant: "info",
        })
      );
      yield put(setIndicator(false));
    } else {
      yield put(setIndicator(false));
      yield put(
        setMessage({
          message: "Error: " + data.message,
          variant: "danger",
        })
      );
    }
  } catch (e) {
    yield put(setIndicator(false));
    /* yield put(
      setMessage({
        message: "Item master data retrieval failed",
        variant: "danger",
        
      })
    ); */
    console.log("error");
    console.log(e);
  }
}

export function* handleUpdateImportFile(action) {
  //console.log("handleUpdateCustomer");
  //console.log(action);
  try {
    yield put(
      setMessage({
        message: "Updating Import File...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload,
    });

    const { data } = response;
    console.log("handleUpdateImportFile", data);

    if (data.status === "success") {
      data.data.forEach((d) => {
        d.key = "" + d.button_label + d.file_name + d.page;
        //d.key = ""+d.customer_id + d.store_number;
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id *1;
      });
      yield put(setImportFiles(data.data));
      yield put(
        setMessage({
          message: "Import file has been updated row was updated",
          variant: "info",
        })
      );
    } else {
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error + " " + data,
          variant: "danger",
        })
      );
    }

    yield put(setIndicator(false));
  } catch (error) {
    console.log("error", error);
    yield put(
      setMessage({
        message: "Import File update failed",
        variant: "warning",
      })
    );
  }
}

export function* handleUpdateExportFileMapping(action) {
  console.log("handleUpdateExportFileMapping");
  //console.log(action);
  try {
    yield put(
      setMessage({
        message: "Updating Export File Mapping...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload,
    });

    const { data } = response;
    console.log("handleUpdateImportFile", data);

    if (data.status === "success") {
      //data.data.forEach((d) => {
      //d.key = "" + d.button_label + d.file_name + d.page;
      //d.key = ""+d.customer_id + d.store_number;
      //d.current_customer_id = d.current_customer_id * 1;
      //d.id = d.id *1;
      //});
      //yield put(setImportFiles(data.data));
      yield put(
        setMessage({
          message: "Export file mapping has been updated row was updated",
          variant: "info",
        })
      );
    } else {
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error + " " + data,
          variant: "danger",
        })
      );
    }

    yield put(setIndicator(false));
  } catch (error) {
    console.log("error", error);
    yield put(
      setMessage({
        message: "Import File update failed",
        variant: "warning",
      })
    );
  }
}

export function* handleUpdateImportFileMapping(action) {
  console.log("handleUpdateImportFileMapping");
  //console.log(action);
  try {
    yield put(
      setMessage({
        message: "Updating Import File Mapping...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload,
    });

    const { data } = response;
    console.log("handleUpdateImportFile", data);

    if (data.status === "success") {
      //data.data.forEach((d) => {
      //d.key = "" + d.button_label + d.file_name + d.page;
      //d.key = ""+d.customer_id + d.store_number;
      //d.current_customer_id = d.current_customer_id * 1;
      //d.id = d.id *1;
      //});
      //yield put(setImportFiles(data.data));
      yield put(
        setMessage({
          message: "Import file mapping has been updated row was updated",
          variant: "info",
        })
      );
    } else {
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error + " " + data,
          variant: "danger",
        })
      );
    }

    yield put(setIndicator(false));
  } catch (error) {
    console.log("error", error);
    yield put(
      setMessage({
        message: "Import File update failed",
        variant: "warning",
      })
    );
  }
}
