import { call, put } from "redux-saga/effects";

import { setRetailFinancialInventory } from "../../slices/RetailFinancialInventorySlice.js";
import { requestViewFile, requestGeneral } from "../requests/index";

import { setMessage } from "../../slices/MessageSlice";
import { setIndicator } from "redux/slices/IndicatorSlice.js";

export function* handleCreateFile(action) {
  try {
    yield put(
      setMessage({
        message: "Processing inventory...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));

    const response = yield call(requestGeneral, action.payload);

    const { data } = response;

    //const data = {status:"success"};

    //data.settings.current_customer_id = data.settings.current_customer_id * 1;

    yield put(setIndicator(false));
    if (data.status === "success") {
      yield put(
        setMessage({
          message: data.message,
          variant: "info",
        })
      );
    } else {
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger",
        })
      );
    }
  } catch (error) {
    yield put(setIndicator(true));
    console.log("error", error);
    yield put(
      setMessage({
        message: "Inventory data retrieval failed",
        variant: "warning",
      })
    );
  }
}

export function* handleGetRetailFinancialInventory(action) {
  try {
    yield put(
      setMessage({
        message: "Gettting inventory...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));

    /*  const response = yield call(requestGeneral, {
      url: "http://192.168.1.64/icp/icp.php",
      action: "getSettings",
    }); */

    /* yield put(
      setMessage({
        message: "Retrieveing settings",
        variant: "info",
        isWorking: true,
      })
    ); */

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      action: action.payload.action,
    });
    const { data } = response;

    //data.settings.current_customer_id = data.settings.current_customer_id * 1;

    yield put(setIndicator(false));
    if (data.status === "success") {
      data.data.forEach((d) => {
        d.extendedTotal =
          d.item_price === null
            ? 0
            : Math.round(d.inv_quantity * d.item_price * 100) / 100;

        //console.log("data", d.item_price, d.extendedTotal);
        /*  d.extendedTotal =
          d.item_price === null
            ? 0
            : Math.round(d.inv_quantity * d.item_price * 100) / 100;
        if (d.department_description !== null) {
          d.department_description = d.department_description.replace("/", "");
        }
        d.dept =
          d.department_description === null
            ? d.department
            : d.department + "-" + d.department_description;
        if (d.department === null && d.department_description === null) {
          d.dept = "9999";
        } */
      });

      ///console.log(data.data);

      /* data.settings.forEach(d=>{
        d.current_customer_id = d.current_customer_id * 1;
        d.id = d.id *1;
  
      }) */
      yield put(setRetailFinancialInventory(data.data));
      yield put(
        setMessage({
          message: "Inventory data received",
          variant: "info",
        })
      );
    } else {
      console.log("no success");
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger",
        })
      );
    }
  } catch (error) {
    yield put(setIndicator(true));
    console.log("error", error);
    yield put(
      setMessage({
        message: "Inventory data retrieval failed",
        variant: "warning",
      })
    );
  }
}

export function* handleProcessInventory(action) {
  try {
    yield put(
      setMessage({
        message: "Processing inventory...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      action: action.payload.action,
    });
    const { data } = response;

    //data.settings.current_customer_id = data.settings.current_customer_id * 1;

    yield put(setIndicator(false));
    if (data.status === "success") {
      yield put(
        setMessage({
          message:
            "Processsing inventory complete. " +
            data.files.length +
            " files were processed",
          variant: "info",
        })
      );
    } else {
      console.log("no success");
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger",
        })
      );
    }
  } catch (error) {
    yield put(setIndicator(true));
    console.log("error", error);
    yield put(
      setMessage({
        message: "Inventory data retrieval failed",
        variant: "warning",
      })
    );
  }
}

export function* handleViewInventoryOutput(action) {
  console.log("handleViewInventoryOutput");
  //console.log(action);
  try {
    yield put(
      setMessage({
        message: "Gettting Inventory Output...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));
    const response = yield call(requestViewFile, {
      ...action.payload,
    });

    console.log(JSON.parse(JSON.stringify(response)));

    console.log("action", action.payload.fileName);
    switch (action.payload.fileName) {
      case "INVENTORY_DETAILED.CSV":
        //yield put(setInventoryOutput(JSON.parse(JSON.stringify(response))));
        break;

      default:
        break;
    }

    yield put(
      setMessage({
        message: "Inventory output data received",
        variant: "info",
      })
    );
    yield put(setIndicator(false));
    //const { data } = response;
    //console.log(data);
  } catch (e) {
    yield put(
      setMessage({
        message: "Inventory data retrieval failed",
        variant: "danger",
      })
    );
    yield put(setIndicator(false));
    console.log(e);
  }
}

export function* handleUpdateFinancialInventory(action) {
  try {
    yield put(
      setMessage({
        message: "Updating inventory...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));

    /*  const response = yield call(requestGeneral, {
      url: "http://192.168.1.64/icp/icp.php",
      action: "getSettings",
    }); */

    /* yield put(
      setMessage({
        message: "Retrieveing settings",
        variant: "info",
        isWorking: true,
      })
    ); */

    //console.log(action.payload);

    const response = yield call(requestGeneral, {
      ...action.payload,
    });
    const { data } = response;

    console.log(response);

    //data.settings.current_customer_id = data.settings.current_customer_id * 1;

    yield put(setIndicator(false));
    if (data.status === "success") {
      data.data.forEach((d) => {
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id *1;
        d.extendedTotal =
          d.item_price === null
            ? 0
            : Math.round(d.inv_quantity * d.item_price * 100) / 100;
        if (d.department_description !== null) {
          d.department_description = d.department_description.replace("/", "");
        }
        d.dept =
          d.department_description === null
            ? d.department
            : d.department + "-" + d.department_description;
        if (d.department === null && d.department_description === null) {
          d.dept = "9999";
        }
      });
      /* data.settings.forEach(d=>{
        d.current_customer_id = d.current_customer_id * 1;
        d.id = d.id *1;
  
      }) */
      yield put(setRetailFinancialInventory(data.data));
      yield put(
        setMessage({
          message: "Inventory data updated",
          variant: "info",
        })
      );
    } else {
      console.log("no success");
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger",
        })
      );
    }
  } catch (error) {
    yield put(setIndicator(true));
    console.log("error", error);
    yield put(
      setMessage({
        message: "Inventory update failed",
        variant: "warning",
      })
    );
  }
}
