import { call, put } from "redux-saga/effects";
import { requestGeneral} from "../requests/index";
import { setMessage } from "../../slices/MessageSlice";
import { setIndicator } from "redux/slices/IndicatorSlice.js";
import { setCustomerLocations, setCustomerLocation } from "redux/slices/SetupCustomerLocationsSlice";


export function* handleAddCustomerLocation(action) {
  //console.log("handleAddCustomerLocations");
  
  try {
     yield put(
      setMessage({
        message: "Adding CustomerLocation...",
        variant: "info"
      })
    ); 
    yield put(setIndicator(true)); 

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload,
    }); 
    
    const { data } = response;
    //console.log("handleAddCustomerLocations",data);
    if (data.status === "success") {
      data.data.forEach(d=>{
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id *1;
  
      })
      yield put(setCustomerLocations(data.data));
       yield put(
        setMessage({
          message: "CustomerLocations data received",
          variant: "info"
        })
      ); 
    } else {
      //console.log("no success");
       yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger"
        })
      ); 
    }
    yield put(setIndicator(false)); 
  } catch (error) {
    console.log("error");
    yield put(
      setMessage({
        message: "Settings data retrieval failed",
        variant: "warning"
      })
    ); 
    yield put(setIndicator(false)); 
  }
}

export function* handleDeleteCustomerLocation(action) {
  //console.log("handleAddCustomerLocations");
  
  try {
     yield put(
      setMessage({
        message: "Deleting Customer...",
        variant: "info"
      })
    ); 
    yield put(setIndicator(true)); 

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload,
    }); 
    
    const { data } = response;
    //console.log("handleDeleteCustomer",data);
    if (data.status === "success") {
      data.data.forEach(d=>{
        d.key = ""+d.customer_id + d.store_number;
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id *1;
  
      })
      yield put(setCustomerLocations(data.data));
       yield put(
        setMessage({
          message: "CustomerLocations data received",
          variant: "info"
        })
      ); 
    } else {
      //console.log("no success");
       yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger"
        })
      ); 
    }
    yield put(setIndicator(false)); 
  } catch (error) {
    console.log("error");
    yield put(
      setMessage({
        message: "Customer data retrieval failed",
        variant: "warning"
      })
    ); 
    yield put(setIndicator(false)); 
  }
}

export function* handleGetCustomerLocations(action) {
  //console.log("handleGetCustomerLocations");
  
  try {
     yield put(
      setMessage({
        message: "Gettting CustomerLocations...",
        variant: "info"
      })
    ); 
    yield put(setIndicator(true)); 

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      action: action.payload.action,
    }); 
    
    const { data } = response;
    //console.log("handleGetCustomerLocations",data);
    if (data.status === "success") {
      data.data.forEach(d=>{
        d.key = ""+d.customer_id + d.store_number;
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id *1;
  
      })
      yield put(setCustomerLocations(data.data));
       yield put(
        setMessage({
          message: "CustomerLocations data received",
          variant: "info"
        })
      ); 
    } else {
      //console.log("no success");
       yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger"
        })
      ); 
    }
    yield put(setIndicator(false)); 
  } catch (error) {
    console.log("error");
    yield put(
      setMessage({
        message: "CustomerLocations data retrieval failed",
        variant: "warning"
      })
    ); 
    yield put(setIndicator(false)); 
  }
}

export function* handleLoadCustomerLocationsFile(action) {
  console.log("handleLoadCustomerLocationsFile");
  console.log(action);
  try {
    yield put(setIndicator(true));

    /* yield put(
      setMessage({
        message: "Gettting item Master...",
        variant: "info",
        
      })
    ); */
    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload
    });

    //console.log("handleLoadAreasFile response",response);
    const { data } = response;
    //console.log(JSON.parse(JSON.stringify(data)));
    console.log(data);
    if (data.status === "success") {
      data.data.forEach(d=>{
        d.key = ""+d.customer_id + d.store_number;
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id *1;
  
      })
      yield put(setCustomerLocations(data.data));
      yield put(
        setMessage({
          message: "CustomerLocations data received ",
          variant: "info"
        })
      );
      yield put(setIndicator(false));
    } else{
      yield put(setIndicator(false));
       yield put(
        setMessage({
          message: "Error: " + data.message,
          variant: "danger",
          
        })
      );

    }
  } catch (e) {
    yield put(setIndicator(false));
    /* yield put(
      setMessage({
        message: "Item master data retrieval failed",
        variant: "danger",
        
      })
    ); */
    console.log("error");
    console.log(e);
  }
}

export function* handleUpdateCustomerLocation(action) {
  //console.log("handleUpdateCustomer");
  //console.log(action);
  try {
     yield put(
      setMessage({
        message: "Updating Customer...",
        variant: "info",
      })
    );
    yield put(setIndicator(true)); 

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload
    });

   
    const { data } = response;
    console.log("handleUpdateCustomerLocation",data)


    if (data.status === "success") {
      data.data.forEach(d=>{
        d.key = ""+d.customer_id + d.store_number;
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id *1;
  
      })
      yield put(setCustomerLocations(data.data));
       yield put(
        setMessage({
          message: "Customer location has been updated row was updated",
          variant: "info"
        })
      ); 
    } else {
       yield put(
        setMessage({
          message: data.message + " error number:" + data.error +" "+ data,
          variant: "danger"
        })
      ); 
    }
    
    yield put(setIndicator(false)); 
    
  } catch (error) {
    console.log("error", error)
    yield put(
      setMessage({
        message: "Customer update failed",
        variant: "warning"
      })
    ); 
  }
}

