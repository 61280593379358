import { call, put } from "redux-saga/effects";

import { setIP } from "../../slices/IPSlice";
import { requestGeneral, requestViewFile } from "../requests/index";

import { setMessage } from "../../slices/MessageSlice";
import { setIndicator } from "redux/slices/IndicatorSlice";

export function* handleUpdateIP(action) {
  try {
    yield put(
      setMessage({
        message: "Updating IP...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload,
    });

    console.log(response);
    const { data } = response;

    //data.settings.current_customer_id = data.settings.current_customer_id * 1;
    //data.settings.forEach(d=>{
    //  d.current_customer_id = d.current_customer_id * 1;

    //})
    /*
     dispatch(
      viewIP({
        action: "viewFile",
        customerID: 0,
        page:"home",
        fileName:  "ip.csv",
      })
    );
    */

    if (data.status === "success") {
      //data.settings.forEach(d=>{
      //d.current_customer_id = d.current_customer_id * 1;
      //d.id = d.id *1;

      //})
      //yield put(setSettings(data.settings));
      yield put(
        setMessage({
          message: "IP data updated",
          variant: "success",
        })
      );
    } else {
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error + " " + data,
          variant: "danger",
        })
      );
    }
  } catch (error) {
    yield put(
      setMessage({
        message: "IP data retrieval failed",
        variant: "warning",
      })
    );
  }
}

export function* handleViewIP(action) {
  try {
    yield put(
      setMessage({
        message: "Gettting IP...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));
    const response = yield call(requestViewFile, {
      ...action.payload,
    });

    JSON.parse(JSON.stringify(response));
    const ipArray = [];
    ipArray.push(response[0]);
    ipArray[0].id = ipArray[0].id * 1;
    ipArray[0].websocket_port = ipArray[0].websocket_port * 1;

    //console.log("action", action.payload);

    //if (response.length > 0) {

    yield put(setIP(ipArray));
    //}

    yield put(
      setMessage({
        message: "IP data received",
        variant: "info",
      })
    );
    yield put(setIndicator(false));

    //const { data } = response;
    //console.log(data);
  } catch (e) {
    yield put(
      setMessage({
        message: "IP data retrieval failed",
        variant: "danger",
      })
    );
    yield put(setIndicator(false));
    console.log(e);
  }
}
