import { createSlice } from "@reduxjs/toolkit";

const setupSectionsSlice = createSlice({
  name: "setupSections",
  initialState: { setupSections: [] },
  reducers: {
    addSections() {},
    deleteSection() {},
    getSections(state, action) {
      //console.log("getSections",action);
    },
    loadSectionsFile(){},
    setSections(state, action) {
      //console.log("setSections", action.payload);
      const data = action.payload;

      return { ...state, setupSections: data };
    },
    setSection(state, action) {
      
      const data = action.payload;
      //console.log(state, data);
      const sections = [
        ...state.setupSections.filter((d) => {
          if (d.store_section === data.store_section) {
            return false;
          } else {
            return true;
          }
        }),
        data,
      ]; 

      return { ...state, setupSections: sections };
    },
    updateSection() {},
  },
});

export const { addSections, deleteSection, getSections,loadSectionsFile, setSections, setSection, updateSection } = setupSectionsSlice.actions;

export default setupSectionsSlice.reducer;
