import { call, put } from "redux-saga/effects";

//import { setInventoryItems} from "../../slices/InventorySlice";
import { setItemMasterItems } from "../../slices/ItemMasterSlice";
import { requestViewFile, requestGeneral } from "../requests/index";
//import { requestGetItemMasterItems, requestLoadItemMasterItems, requestProcessAction, requestViewItemMasterItems } from "../requests/ItemMasterRequest";
import { setMessage } from "../../slices/MessageSlice";
import { setIndicator } from "redux/slices/IndicatorSlice";
import { setInventoryOutput } from "redux/slices/InventoryOutputSlice.js";

//The action parameter takes the payload coming from the the call on Customers.js
/* export function* handleGetItemMasterItems(action) {
  console.log("handleGetItemMaster");
  console.log(action)
  try {
    yield put(
      setMessage({
        message: "Gettting item Master...",
        variant: "info",
        isWorking: true,
      })
    );

    const response = yield call(requestGetItemMasterItems, {
      url: "http://localhost/reactAPI/invAPI.php",
      action: "getItemMasterItems",
    });
    const { data } = response;

    try {
   
      JSON.parse(JSON.stringify(data));
      
      
      if (data.status === "success") {
        yield put(setItemMasterItems(data.customers));
       
        yield put(
          setMessage({
            message: "Item master data received",
            variant: "success",
            isWorking: false,
          })
        );
      } else {
        yield put(
          setMessage({
            message: data.message + " error number:" + data.error,
            variant: "danger",
            isWorking: false,
          })
        );
      }
  
    } catch (e) {
      
      yield put(
        setMessage({
          message: data,
          variant: "danger",
          isWorking: false,
      })
      );
      return;
    }



    
    

   

    
  } catch (error) {
   
    yield put(
      setMessage({
        message: "Customers data retrieval failed",
        variant: "warning",
        isWorking: false,
      })
    );
  }
} */

export function* handleViewItemMasterItems(action) {
  console.log("handleViewItemMasterItems", action.payload);

  try {
    yield put(
      setMessage({
        message: "Gettting " + action.payload.fileName + "...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));
    const response = yield call(requestViewFile, {
      ...action.payload,
    });

    //JSON.parse(JSON.stringify(response));

    /*
    SKUNumber,UPC   ,Name,Size ,Case Pack,Status    ,Retail,,Cost
    2740     ,496340,coke,12 oz,12       ,A - Active,2.59  ,,1.60

    const addresses = [...]; // Some array I got from async call

const uniqueAddresses = Array.from(new Set(addresses.map(a => a.id)))
 .map(id => {
   return addresses.find(a => a.id === id)
 })

 item        ,description    ,quantity on hand,retail,department,department description
 000000001007,12OZ SINGLE CAN,0               ,0.52  ,          ,

    */
    //console.log(response);

    switch (action.payload.fileName) {
      case "COMMISSARY.CSV":
        /*
        const filteredArr01 = response.reduce((acc, current) => {
          const x = acc.find(item => item["Item SKU Code"] === current["Item SKU Code"]);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        filteredArr01.forEach(d=>{
          d["Item Cost"] = d["Item Cost"] * 1;
        })
        yield put(setItemMasterItems(filteredArr01));
        */
        response.forEach((d) => {
          d["Item Cost"] = d["Item Cost"] * 1;
        });
        yield put(setItemMasterItems(response));
        break;
      case "ECIS1.CSV":
      case "ECIS2.CSV":
        //store,upc,sku,area,description,quantity
        //store,extra,department,sku,upc,quantity,retail,extended,extra2
        console.log(response);
        const totale1 = {
          entries: 0,
          quantities: 0,
          amount: 0,
        };
        response.forEach((d) => {
          d.key =
            d.upc + d.sku + (d.area === undefined ? d.department : d.area);
          d.quantity = d.quantity * 1;
          d.extended_cost = d.quantity * (d.retail / 100) * 1;
          d.extended_cost = Math.round(d.extended_cost * 100) / 100;
          //d.extended_cost =  d.item_price !== 0 ?(d.item_price * d.quantity).toFixed(4): 0;
          //console.log(d.extended_cost);

          totale1.entries++;
          totale1.quantities = totale1.quantities + d.quantity;
          totale1.amount = totale1.amount + d.extended_cost;
        });
        console.log(totale1);
        yield put(
          setInventoryOutput({
            file_name: action.payload.fileName,
            items: JSON.parse(JSON.stringify(response)),
            total: totale1,
          })
        );
        break;
      case "h1.csv":
        /*
        const filteredArr02 = response.reduce((acc, current) => {
          const x = acc.find(item => item.UPC === current.UPC);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        filteredArr02.forEach(d=>{
          d.Retail = d.Retail * 1;
          d.Cost  = d.Cost * 1;
        })
        yield put(setItemMasterItems(filteredArr02));
        */
        response.forEach((d) => {
          d.Retail = d.Retail * 1;
          d.Cost = d.Cost * 1;
        });
        yield put(setItemMasterItems(response));
        break;
      case "ICS.CSV":
        console.log(response);
        const total0 = {
          entries: 0,
          quantities: 0,
          amount: 0,
        };
        response.forEach((d) => {
          d.key = d.item_id;
          d.quantity = d.quantity * 1;
          d.extended_cost = d.extended_cost * 1;
          //d.extended_cost =  d.item_price !== 0 ?(d.item_price * d.quantity).toFixed(4): 0;
          //console.log(d.extended_cost);

          total0.entries++;
          total0.quantities = total0.quantities + d.quantity;
          total0.amount = total0.amount + d.extended_cost;
        });
        console.log(total0);
        yield put(
          setInventoryOutput({
            file_name: action.payload.fileName,
            items: JSON.parse(JSON.stringify(response)),
            total: total0,
          })
        );
        break;
      case "INVENTORY_DETAILED.CSV":
        const total = {
          entries: 0,
          quantities: 0,
          amount: 0,
        };
        response.forEach((d) => {
          d.key = d.id;
          d.price = d.price * 1;
          d.search_area = "^" + d.area;
          d.search_section = "@" + d.section;
          if (d.department !== null && d.depardment !== "") {
            d.search_department = "$" + d.department;
          }
          d.quantity = d.quantity * 1;
          d.quantity_on_hand =
            d.quantity_on_hand === "" || d.quantity_on_hand === null
              ? 0
              : d.quantity_on_hand * 1;
          d.sequence = d.sequence * 1;
          d.cost = d.cost * 1;
          d.extended_cost = d.extended_cost * 1;
          d.extended_price = d.extended_price * 1;
          d.entry_time = d.entry_time * 1;
          total.entries++;
          total.quantities = total.quantities + d.quantity;
          total.amount = total.amount + d.quantity * d.price;
        });
        console.log(action.payload, total);
        yield put(
          setInventoryOutput({
            file_name: action.payload.fileName,
            items: JSON.parse(JSON.stringify(response)),
            total: total,
          })
        );
        //yield put(setInventoryOutput({file_name:action.payload.fileName,file_type: action.payload.fileType,items:response}));
        break;
      case "INVENTORY_SUMMARIZED.CSV":
        const total2 = {
          entries: 0,
          quantities: 0,
          amount: 0,
        };
        response.forEach((d) => {
          d.key = d.item_id;
          d.price = d.price * 1;
          d.quantity = d.quantity * 1;
          if (d.department !== null && d.depardment !== "") {
            d.search_department = "$" + d.department;
          }
          d.quantity_on_hand =
            d.quantity_on_hand === "" || d.quantity_on_hand === null
              ? 0
              : d.quantity_on_hand * 1;
          d.cost = d.cost * 1;
          d.extended_cost = d.extended_cost * 1;
          d.extended_price = d.extended_price * 1;
          total2.entries++;
          total2.quantities = total2.quantities + d.quantity;
          total2.amount =
            total2.amount + Math.round(d.quantity * d.price * 100) / 100;
        });

        yield put(
          setInventoryOutput({
            file_name: action.payload.fileName,
            file_type: action.payload.fileType,
            items: response,
            total: total2,
          })
        );
        break;
      case "ITEM_MASTER.CSV":
        console.log(response.length);
        var count = 0;
        var hasHeadings = false;

        response.forEach((d) => {
          //d["quantity on hand"] = d["quantity on hand"] * 1;
          if (count === 0) {
            if (d.hasOwnProperty("upc") || d.hasOwnProperty("item")) {
              //console.log(d);
              hasHeadings = true;
            } else {
              hasHeadings = false;
              //console.log("does not have property")
            }
            //console.log(parseInt('195'))
          }
          if (d.hasOwnProperty("retail")) {
            d.retail = d.retail * 1;
          }
          if (d.hasOwnProperty("quantity_on_hand")) {
            d.quantity_on_hand = d.quantity_on_hand * 1;
          }

          count++;
          //d.onhands = d.onhands * 1;
        });
        if (hasHeadings) {
          yield put(
            setItemMasterItems({
              file_name: action.payload.fileName,
              file_type: action.payload.fileType,
              items: response,
            })
          );
          yield put(
            setMessage({
              message: action.payload.fileName + " data received",
              variant: "info",
            })
          );
        } else {
          yield put(
            setMessage({
              message:
                action.payload.fileName + " does not have column headings",
              variant: "danger",
            })
          );
        }
        break;
      case "roses.csv":
        response.forEach((d) => {
          d.count = d.count * 1;
          d.retail = d.retail * 1;
          d.onhands = d.onhands * 1;
        });
        yield put(setItemMasterItems(response));
        break;

      default:
        const total3 = {
          entries: 0,
          quantities: 0,
          amount: 0,
        };

        let key = [];
        for (let i = 0; i < action.payload.columns.length; i++) {
          //console.log(action.payload.columns[i].id);
          switch (action.payload.columns[i].is_summed) {
            case 0:
              key.push(action.payload.columns[i].title);
              break;
            default:
              break;
          }
        }
        //key = key + "";
        //console.log("key", key);
        response.forEach((d) => {
          d.key = "";
          for (let i = 0; i < key.length; i++) {
            d.key = d.key + "" + d[key[i]];

            //console.log("value", d[key[i]]);
          }

          //console.log("d", d);
          //d.price = 0;
          //d.search_area = "^" + d.area;
          //d.search_section = "@" + d.section;
          //if (d.department !== null && d.depardment !== "") {
          //d.search_department = "$" + d.department;
          // }
          d.quantity = d.count !== undefined ? d.count * 1 : d.quantity * 1;
          //d.quantity_on_hand =
          //  d.quantity_on_hand === "" || d.quantity_on_hand === null
          //    ? 0
          //    : d.quantity_on_hand * 1;
          //d.sequence = d.sequence * 1;
          //d.cost = d.cost * 1;
          //d.extended_cost = d.extended_cost * 1;
          //d.extended_price = d.extended_price * 1;
          //d.entry_time = d.entry_time * 1;
          total3.entries++;
          total3.quantities = total3.quantities + d.quantity;
          //total3.amount = total3.amount + d.quantity * d.price;
        });
        //console.log(action.payload, total3);
        yield put(
          setInventoryOutput({
            file_name: action.payload.fileName,
            items: JSON.parse(JSON.stringify(response)),
            total: total3,
          })
        );
        //yield put(setInventoryOutput({file_name:action.payload.fileName,file_type: action.payload.fileType,items:response}));

        break;
    }

    yield put(setIndicator(false));
    //const { data } = response;
    //console.log(data);
  } catch (e) {
    yield put(
      setMessage({
        message: "Data retrieval failed",
        variant: "danger",
      })
    );
    yield put(setIndicator(false));
    console.log(e);
  }
}

export function* handleLoadItemMasterItems(action) {
  //console.log("handleLoadItemMasterItems");
  //console.log(action);
  try {
    yield put(setIndicator(true));

    /* yield put(
      setMessage({
        message: "Gettting item Master...",
        variant: "info",
        
      })
    ); */
    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload,
    });

    //console.log("handleLoadItemMasterItems response", response);
    const { data } = response;
    //console.log(JSON.parse(JSON.stringify(data)));
    //console.log(data);
    if (data.status === "success") {
      yield put(
        setMessage({
          message: data.message,
          variant: "info",
        })
      );
      yield put(setIndicator(false));
    } else {
      yield put(setIndicator(false));
      yield put(
        setMessage({
          message: "Error: " + data.message,
          variant: "danger",
        })
      );
    }
  } catch (e) {
    yield put(setIndicator(false));
    /* yield put(
      setMessage({
        message: "Item master data retrieval failed",
        variant: "danger",
        
      })
    ); */
    console.log("error");
    console.log(e);
  }
}
/* 
export function* handleProcessAction(action) {
  console.log("handleProcessAction");
  console.log(action);
  try {
    yield put(
      setMessage({
        message: "Gettting item Master...",
        variant: "info",
        isWorking: true,
      })
    );
    const response = yield call(requestProcessAction, {
      url: "http://localhost/reactAPI/invAPI.php",
      ...action.payload
    });
    const { data } = response;
    console.log(data);
    try {
   
      JSON.parse(JSON.stringify(data));
      
      
      if (data.status === "success") {
        //yield put(setItemMasterItems(data.customers));

       
        yield put(
          setMessage({
            message: "Item master " + data.itemMaster + " has been converted",
            variant: "success",
            isWorking: false,
          })
        );
      } else {
        yield put(
          setMessage({
            message: data.message + " error number:" + data.error,
            variant: "danger",
            isWorking: false,
          })
        );
      }
  
    } catch (e) {
      
      yield put(
        setMessage({
          message: data + " " + e,
          variant: "danger",
          isWorking: false,
      })
      );
      return;
    }
    
  } catch (e) {}
} */

/*
ip_address
backroom_section_start
backroom_section_end
store_file
store_number
*/
