import { createSlice } from "@reduxjs/toolkit";

const setupDepartmentsSlice = createSlice({
  name: "setupDepartments",
  initialState: { setupDepartments: [] },
  reducers: {
    addDepartments() {},
    deleteDepartment() {},
    getDepartments(state, action) {
      //console.log("getSections",action);
    },
    loadDepartmentsFile(){},
    setDepartments(state, action) {
      //console.log("setSections", action.payload);
      const data = action.payload;

      return { ...state, setupDepartments: data };
    },
    setDepartment(state, action) {
      
      const data = action.payload;
      //console.log(state, data);
      const departments = [
        ...state.setupDepartments.filter((d) => {
          if (d.store_department === data.store_department) {
            return false;
          } else {
            return true;
          }
        }),
        data,
      ]; 

      return { ...state, setupDepartments: departments };
    },
    updateDepartment() {},
  },
});

export const { addDepartments, deleteDepartment, getDepartments,loadDepartmentsFile, setDepartments, setDepartment, updateDepartment } = setupDepartmentsSlice.actions;

export default setupDepartmentsSlice.reducer;
