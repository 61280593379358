import { createSlice } from "@reduxjs/toolkit";

const setupCustomerLocationsSlice = createSlice({
  name: "setupCustomerLocations",
  initialState: { setupCustomerLocations: [] },
  reducers: {
    addCustomerLocation() {},
    deleteCustomerLocation() {},
    getCustomerLocations(state, action) {
      //console.log("getCustomersLocations",action);
    },
    loadCustomerLocationsFile(){},
    setCustomerLocations(state, action) {
      //console.log("setCustomerLocations", action.payload);
      const data = action.payload;

      return { ...state, setupCustomerLocations: data };
    },
    setCustomerLocation(state, action) {
      console.log("setCustomerLocation", action.payload);
      
      const data = action.payload;
      console.log("data", data);
      const customerLocations = [
        ...state.setupCustomerLocations.filter((d) => {
          if (d.customer_id === data.customer_id) {
            return false;
          } else {
            return true;
          }
        }),
        data,
      ]; 
      console.log("locations", customerLocations);

      return { ...state, setupCustomerLocations: customerLocations };
    },
    updateCustomerLocation() {},
  },
});

export const { addCustomerLocation, deleteCustomerLocation, getCustomerLocations,loadCustomerLocationsFile, setCustomerLocations, setCustomerLocation, updateCustomerLocation } = setupCustomerLocationsSlice.actions;

export default setupCustomerLocationsSlice.reducer;
