import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { watcherSaga } from "./sagas/rootSaga";
import appsSlice from "./slices/AppsSlice";
import buttonsSlice from "./slices/ButtonsSlice";
import columnsSlice from "./slices/ColumnsSlice";
import customersSlice from "./slices/CustomersSlice";
import dashboardSlice from "./slices/DashboardSlice";
import indicatorSlice from "./slices/IndicatorSlice";
import inventoryOutputSlice from "./slices/InventoryOutputSlice";
import importFilesSlice from "./slices/ImportFilesSlice";
import ipSlice from "./slices/IPSlice";
import itemMasterSlice from "./slices/ItemMasterSlice";
import messageSlice from "./slices/MessageSlice";
import retailFinancialInventorySlice from "./slices/RetailFinancialInventorySlice";
import retailInventorySlice from "./slices/RetailInventorySlice";
import settingsSlice from "./slices/SettingsSlice";
import setupAreasSlice from "./slices/SetupAreasSlice";
import setupCustomerLocationsSlice from "./slices/SetupCustomerLocationsSlice";
import setupCustomersSlice from "./slices/SetupCustomersSlice";
import setupDepartmentsSlice from "./slices/SetupDepartmentsSlice";
import setupSectionsSlice from "./slices/SetupSectionsSlice";

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  apps: appsSlice,
  buttons: buttonsSlice,
  columns: columnsSlice,
  customers: customersSlice,
  dashboardInfo: dashboardSlice,
  importFiles: importFilesSlice,
  inventoryOutput: inventoryOutputSlice,
  ip: ipSlice,
  isProcessing: indicatorSlice,
  itemMasterItems: itemMasterSlice,
  message: messageSlice,
  retailFinancialInventory: retailFinancialInventorySlice,
  retailInventory: retailInventorySlice,
  settings: settingsSlice,
  setupAreas: setupAreasSlice,
  setupCustomerLocations: setupCustomerLocationsSlice,
  setupCustomers: setupCustomersSlice,
  setupDepartments: setupDepartmentsSlice,
  setupSections: setupSectionsSlice,
});

const store = configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      immutableCheck: false,
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(watcherSaga);

export default store;
