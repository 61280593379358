import { createSlice } from "@reduxjs/toolkit";

const appsSlice = createSlice({
  name: "apps",
  initialState: { apps: [] },
  reducers: {
    downloadToken() {},
    getApps(state, action) {
      //console.log("getSettings",action);
    },
    setApps(state, action) {
      //console.log("setSettings", action.payload);
      const appsData = action.payload;

      return { ...state, apps: appsData };
    },
    setApp(state, action) {
      const appData = action.payload;
      const settings = [
        ...state.apps.filter((d) => {
          if (d.id === appData.id) {
            return false;
          } else {
            return true;
          }
        }),
        appData,
      ];

      return { ...state, settings: settings };
    },
    updateApps() {},
    updateToken(state, action) {
      const settings = [...state.settings];
      console.log("settings", settings.length);
      if (settings.length > 0) {
        const settingsArray = [];
        const settings2 = {
          ...settings[0],
          full_access_token: action.payload.token,
        };
        console.log(action.payload, settings2);
        settingsArray.push(settings2);

        return { ...state, settings: settingsArray };
      }

      //console.log(action.payload);
    },
  },
});

export const {
  downloadToken,
  getApps,
  setApps,
  setApp,
  updateApps,
  updateToken,
} = appsSlice.actions;

export default appsSlice.reducer;
