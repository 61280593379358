/*!


*/
import React from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ReactBSAlert from "react-bootstrap-sweetalert";
import cellEditFactory from "react-bootstrap-table2-editor";

import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  Container,
  Button,
  Badge,
  Row,
  Col,
  InputGroup,
  Input,
  InputGroupAddon,
  CardHeader,
} from "reactstrap";

import crossfilter from "crossfilter2";
import * as d3 from "d3";
// core components
import SimpleHeader from "components/Headers/SimpleHeaderPonce.js";

import { getRetailInventory } from "redux/slices/RetailInventorySlice";

import { updateInventory } from "redux/slices/RetailInventorySlice";

function Details() {
  const dispatch = useDispatch();
  var formatDecimal2 = d3.format(",.2f");

  //const fileName = "sectionTotals.csv";

  const { retailInventory } = useSelector((state) => state.retailInventory);

  //const params = useRouteMatch("/admin/section/:section").params;
  //const params = useParams();
  const { type, id } = useParams();
  console.log(type, id);

  const section = "0001-0001";
  const arrSection = section.split("-");

  const [isChangeAlertShowing, setIsChangeAlertShowing] = React.useState(false);
  const [changeTo, setChangeTo] = React.useState("");
  const [fileName, setFileName] = React.useState("details.csv");
  const [dataTable, setDataTable] = React.useState([]);
  const [ndx, setNDX] = React.useState(null);
  const [groupAll, setGroupAll] = React.useState(null);
  const { ip } = useSelector((state) => state.ip);

  //let ndx = null

  //let groupAll = null

  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "inv_sequence",
      text: "Seq",
      sort: true,
      csvType: "number",
      headerAlign: "right",
      align: "right",
    },
    {
      dataField: "item_id",
      text: "Item",
      csvType: "number",
      sort: true,
    },
  ]);

  //console.log(dataTable);

  const setChangeInfo = {
    title: "Are you sure?",
    confirm: "Yes, change " + type + " from " + id + " to " + changeTo,
    message: <span>You wont be able to revert this!</span>,
    cancel: "Cancel",
  };

  //const dataTable = retailInventory.filter(d=>d.inv_section === arrSection[0]);

  //console.log("Area sections", typeGroup.all())

  function handleChange(event) {
    //console.log("handleAreaChange")
    //console.log(event.target);
    const { value } = event.target;
    setChangeTo(value);
    //console.log(areas);
  }

  function handleClick(e) {
    console.log(e.target.id);
  }
  React.useEffect(() => {
    if (retailInventory.length > 0) {
      switch (type) {
        case "area":
          setDataTable(retailInventory.filter((d) => d.inv_area === id));
          setFileName(type + "_" + id + ".csv");
          break;
        case "areaSection":
          const arrTemp = id.split("-");
          const area = arrTemp[1];
          const section = arrTemp[0];
          setDataTable(
            retailInventory.filter(
              (d) => d.inv_section === section && d.inv_area === area
            )
          );
          setFileName(type + "_" + area + "_" + section + ".csv");
          break;
        case "auditor":
          const auditor = id;
          setDataTable(
            retailInventory.filter((d) => d.inv_auditor === auditor)
          );
          setFileName(type + "_" + auditor + ".csv");
          break;
        case "department":
          const department = id;
          setDataTable(
            retailInventory.filter((d) => d.department === department)
          );
          setFileName(type + "_" + department + ".csv");
          break;
        case "id":
          setDataTable(retailInventory.filter((d) => d.id === id));
          setFileName(type + "_" + id + ".csv");
          break;
        case "item":
          setDataTable(retailInventory.filter((d) => d.item_id === id));
          setFileName(type + "_" + id + ".csv");
          break;
        case "section":
          const sectionValue = id;
          setDataTable(
            retailInventory.filter((d) => d.inv_section === sectionValue)
          );
          setFileName(type + "_" + sectionValue + ".csv");
          break;
        default:
          break;
      }

      //console.log(type, id);
    }
  }, [id, retailInventory, retailInventory.length, type]);

  React.useEffect(() => {
    ndx !== null && setGroupAll(ndx.groupAll());
  }, [ndx]);

  React.useEffect(() => {
    if (dataTable.length > 0) {
      setNDX(crossfilter(dataTable));

      const cols = [];
      cols.push({
        dataField: "df1",
        csvExport: false,
        isDummyField: true,
        text: "Actions",
        csvType: "number",
        formatter: (cellContent, row) => {
          return (
            <>
              {type !== "item" && (
                <Button
                  className="buttons-copy buttons-html5"
                  color="success"
                  size="sm"
                  id={"btn-select-item-" + row.id}
                >
                  <Link to={"/admin/details/item/" + row.item_id}>
                    <i
                      onClick={handleClick}
                      id={"btn-select-" + row.item_id}
                      title="Go to this item"
                      className="fa fa-barcode "
                      aria-hidden="true"
                    ></i>
                  </Link>
                </Button>
              )}

              {type !== "id" && (
                <Button
                  className="buttons-copy buttons-html5"
                  color="info"
                  size="sm"
                  id={"btn-select-item-" + row.id}
                >
                  <Link to={"/admin/details/id/" + row.id}>
                    <i
                      onClick={handleClick}
                      id={"btn-select-" + row.item_id}
                      title="Go to this item"
                      className="fa fa-key "
                      aria-hidden="true"
                    ></i>
                  </Link>
                </Button>
              )}
            </>
          );
        },
      });
      const keys = Object.keys(dataTable[0]);
      for (let i = 0; i < keys.length; i++) {
        const a =
          isRegProperty(dataTable[0][keys[i]]) === "number" ? "right" : "left";
        let isDummy = false;
        // = keys[i].includes("search") ?true:false;

        //console.log(keys[i]);
        //const tmpColumn = currentColumns.filter(d=> d.column_title === keys[i]);
        //console.log("keys[i]", keys[i], tmpColumn);
        //if (tmpColumn.length === 1) {
        //isDummy = tmpColumn[0].hidden === "Y"?true:false;
        //isDummy = false;
        //} else {
        //isDummy = keys[i].includes("search") ?true:false;
        //}
        let c = {};

        if (keys[i] === "inv_quantity") {
          c = {
            dataField: keys[i],
            text: keys[i],
            sort: true,
            headerAlign: a,
            isDummyField: isDummy,
            hidden: isDummy,
            align: a,
            editable: true,
            csvType: "number",
          };
        } else {
          c = {
            dataField: keys[i],
            text: keys[i],
            sort: true,

            headerAlign: a,
            isDummyField: isDummy,
            hidden: isDummy,
            align: a,
            editable: false,
            csvType: "number",
          };
        }

        //console.log(keys[i]);
        cols.push(c);
      }

      setTableColumns(cols);
    }
  }, [dataTable]);

  React.useEffect(() => {
    retailInventory.length === 0 &&
      ip.length === 1 &&
      dispatch(
        getRetailInventory({
          action: "getRetailInventory",
          method: "get",
          url:
            "http://" +
            ip[0].ip +
            ":" +
            ip[0].websocket_port +
            "/retailInventory",
        })
      );
  }, [dispatch, ip, retailInventory.length]);

  //These are necessary for the table
  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,

    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;
  function priceFormatter(cell, row) {
    return formatDecimal2(cell);
  }

  const cancelAlert = (whichAlert) => {
    console.log("cancelAlert", whichAlert);
    //setIsAreaRangeAlertShowing(false);
    setIsChangeAlertShowing(false);
  };

  const confirmAlert = (whichAlert) => {
    console.log("confirmAlert", whichAlert, arrSection[0], changeTo);
    //setIsAreaRangeAlertShowing(false);
    //switch (whichAlert) {
    //case "area":
    dispatch(
      updateInventory({
        action: "updateInventory",
        data: { change: type, from: id, to: changeTo },
        method: "post",
        url: "http://192.168.1.64:4001/updateInventory",
      })
    );
    //break;
    //default:
    //break;
    //}

    setIsChangeAlertShowing(false);
  };

  const showAlert = (whichAlert) => (event) => {
    //console.log(whichAlert, event.target.id +"<");
    setIsChangeAlertShowing(true);
  };

  function isRegProperty(obj) {
    var t = typeof obj;
    return t;
  }

  return (
    <>
      <SimpleHeader name="" parentName="Inventory" />
      {dataTable.length > 0 && (
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader className="border-0">
              <h3 className="mb-0">{type}</h3>
              <Row>
                <Col className="bg-light text-center" sm={4}>
                  Entries
                </Col>
                <Col className="bg-light text-center" sm={4}>
                  Quantity
                </Col>
                <Col className="bg-light text-center" sm={4}>
                  Amount
                </Col>
                <Col className="text-center" sm={4}>
                  {groupAll !== null && groupAll.reduceCount().value()}
                </Col>
                <Col className="text-center" sm={4}>
                  {groupAll !== null &&
                    formatDecimal2(
                      groupAll.reduceSum((d) => d.inv_quantity).value()
                    )}
                </Col>
                <Col className="text-center" sm={4}>
                  {groupAll !== null &&
                    formatDecimal2(
                      groupAll.reduceSum((d) => d.extendedTotal).value()
                    )}
                </Col>
              </Row>
            </CardHeader>
            <ToolkitProvider
              data={dataTable}
              keyField="id"
              columns={tableColumns}
              search
              exportCSV={{
                //fileName: "areaTotals.csv",
                fileName: fileName,
                separator: ",",
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(props) => (
                <div className="py-4 table-responsive">
                  <div
                    id="datatable-basic_filter"
                    className="dataTables_filter px-4 pb-1"
                  >
                    <label>
                      Search:
                      <SearchBar
                        className="form-control-sm"
                        placeholder=""
                        {...props.searchProps}
                      />
                    </label>
                    <br />
                    <ExportCSVButton {...props.csvProps}>
                      Export {fileName}
                    </ExportCSVButton>
                  </div>
                  {type === "area" && (
                    <Row>
                      <Col sm={8}>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <Button
                              onClick={showAlert()}
                              disabled={
                                changeTo.length !== 4 || changeTo === id
                                  ? true
                                  : false
                              }
                              color="primary"
                            >
                              change area {id} to
                            </Button>
                          </InputGroupAddon>
                          <Input
                            value={changeTo}
                            onChange={handleChange}
                            name="change-item-to-value"
                            placeholder="Area"
                            type="number"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  )}
                  {type === "section" && (
                    <Row>
                      <Col sm={8}>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <Button
                              onClick={showAlert()}
                              disabled={
                                changeTo.length !== 4 || changeTo === id
                                  ? true
                                  : false
                              }
                              color="primary"
                            >
                              change section {id} to
                            </Button>
                          </InputGroupAddon>
                          <Input
                            value={changeTo}
                            onChange={handleChange}
                            name="change-item-to-value"
                            placeholder="Section"
                            type="number"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  )}
                  {/* <Row>
                  <Col sm={8}>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <Button
                        onClick={showAlert()} 
                        disabled={changeTo.length !== 4 || changeTo === arrSection[0]  ? true: false}
                        color="primary">
                          change section {arrSection[0]} to
                        </Button>
                      </InputGroupAddon>
                      <Input
                        value={changeTo}
                        onChange={handleChange}
                        name="change-section-to-value"
                        placeholder="Sec"
                        type="number"
                      />
                    </InputGroup>
                  </Col>
                </Row> */}
                  {/* <div>
                  <Badge color="primary" pill>
                    Section: <span className="bg-gray">{section}</span>
                  </Badge>
                  <Badge color="info" pill>
                    Entries:{" "}
                    <span className="bg-white">
                      {groupAll.reduceCount().value()}
                    </span>
                  </Badge>
                  <Badge color="info" pill>
                    Qty:{" "}
                    <span className="bg-white">
                      {groupAll.reduceSum((d) => d.inv_quantity).value()}
                    </span>
                  </Badge>
                  <Badge color="info" pill>
                    Amt:{" "}
                    <span className="bg-white">
                      {formatDecimal2(
                        groupAll
                          .reduceSum((d) => d.inv_quantity * d.item_price)
                          .value()
                      )}
                    </span>
                  </Badge>
                </div> */}

                  <BootstrapTable
                    {...props.baseProps}
                    pagination={pagination}
                    striped
                    bootstrap4={true}
                    bordered={false}
                    cellEdit={cellEditFactory({
                      mode: "click",
                      blurToSave: true,
                      beforeSaveCell(oldValue, newValue, row, column, done) {
                        const newRow = {
                          ...row,
                          [column.dataField]: newValue,
                        };
                        newRow.inv_quantity = newRow.inv_quantity * 1;

                        console.log(newRow);

                        //console.log(setupAreas)
                        //let temp = [...setupAreas,newRow];
                        //console.log(temp);
                        //dispatch(setArea(newRow));
                        if (ip.length === 1) {
                          dispatch(
                            updateInventory({
                              url:
                                "http://" +
                                ip[0].ip +
                                ":" +
                                ip[0].websocket_port +
                                "/updateInventory",
                              data: { change: "item", item: newRow },
                              action: "updateInventory",
                              method: "post",
                            })
                          );
                        }

                        done(false); // The changes are handled by the dispatch

                        return { async: true };
                      },
                    })}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Card>
        </Container>
      )}
      {isChangeAlertShowing && (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={setChangeInfo.title}
          onConfirm={() => confirmAlert(type)}
          onCancel={() => cancelAlert(type)}
          showCancel
          cancelBtnBsStyle="secondary"
          confirmBtnText={setChangeInfo.confirm}
          confirmBtnBsStyle="danger"
          cancelBtnText={setChangeInfo.cancel}
          btnSize=""
        >
          {setChangeInfo.message}
        </ReactBSAlert>
      )}
    </>
  );
}

export default Details;
