import { call, put } from "redux-saga/effects";
import { setApps, setApp } from "../../slices/AppsSlice";
import { requestGeneral } from "../requests/index";
import { setMessage } from "../../slices/MessageSlice";
import { setIndicator } from "redux/slices/IndicatorSlice.js";

export function* handleGetApps(action) {
  try {
    yield put(
      setMessage({
        message: "Gettting settings...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));

    /*  const response = yield call(requestGeneral, {
      url: "http://192.168.1.64/icp/icp.php",
      action: "getSettings",
    }); */

    /* yield put(
      setMessage({
        message: "Retrieveing settings",
        variant: "info",
        isWorking: true,
      })
    ); */

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      action: action.payload.action,
    });

    const { data } = response;
    console.log(data);

    //data.settings.current_customer_id = data.settings.current_customer_id * 1;

    if (data.status === "success") {
      data.data.forEach((d) => {
        d.discount = d.discount * 1;
        d.price = d.price * 1;
        d.id = d.id * 1;
        d.cost = d.price - d.discount;
        //d.current_customer_id = d.current_customer_id * 1;
        //d.id = d.id * 1;
      });
      yield put(setApps(data.data));
      yield put(
        setMessage({
          message: "Settings data received",
          variant: "info",
        })
      );
    } else {
      //console.log("no success");
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger",
        })
      );
    }
    yield put(setIndicator(false));
  } catch (error) {
    console.log("error");
    yield put(
      setMessage({
        message: "Settings data retrieval failed",
        variant: "warning",
      })
    );
    yield put(setIndicator(false));
  }
}

export function* handleUpdateApps(action) {
  //console.log("handleUpdateSettings");
  //console.log(action);
  try {
    yield put(
      setMessage({
        message: "Updating apps...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload,
    });

    const { data } = response;
    //console.log(data)

    //data.settings.current_customer_id = data.settings.current_customer_id * 1;
    //data.settings.forEach(d=>{
    //  d.current_customer_id = d.current_customer_id * 1;

    //})

    if (data.status === "success") {
      //data.data.forEach(d=>{
      //d.current_customer_id = d.current_customer_id * 1;
      //d.id = d.id *1;

      //})
      //yield put(setSetting(data.data));
      yield put(setApps(data.data));
      yield put(
        setMessage({
          message: "Apps data updated",
          variant: "info",
        })
      );
    } else {
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error + " " + data,
          variant: "danger",
        })
      );
    }

    yield put(setIndicator(false));
  } catch (error) {
    console.log("error", error);
    yield put(
      setMessage({
        message: "Settings data retrieval failed",
        variant: "warning",
      })
    );
  }
}
