import { call, put } from "redux-saga/effects";

import { setInventoryOutput } from "redux/slices/InventoryOutputSlice.js";
import { requestViewFile } from "../requests/index";

import { setMessage } from "../../slices/MessageSlice";
import { setIndicator } from "redux/slices/IndicatorSlice.js";

export function* handleViewInventoryOutput(action) {
  console.log("handleViewInventoryOutput");
  try {
    yield put(
      setMessage({
        message: "Gettting Inventory Output...",
        variant: "info",
      })
    );
    yield put(setIndicator(true));
    const response = yield call(requestViewFile, {
      ...action.payload,
    });

    switch (action.payload.fileName) {
      case "INVENTORY_DETAILED.CSV":
        response.forEach((d) => {
          d.price = d.price * 1;
          d.quantity = d.quantity * 1;
          d.quantity_on_hand =
            d.quantity_on_hand === "" || d.quantity_on_hand === null
              ? 0
              : d.quantity_on_hand * 1;
          d.sequence = d.sequence * 1;
          d.cost = d.cost * 1;
          d.extended_cost = d.extended_cost * 1;
          d.extended_price = d.extended_price * 1;
          d.entry_time = d.entry_time * 1;
        });
        break;
      case "INVENTORY_SUMMARIZED.CSV":
        response.forEach((d) => {
          d.price = d.price * 1;
          d.quantity = d.quantity * 1;
          d.quantity_on_hand =
            d.quantity_on_hand === "" || d.quantity_on_hand === null
              ? 0
              : d.quantity_on_hand * 1;
          d.cost = d.cost * 1;
          d.extended_cost = d.extended_cost * 1;
        });
        //yield put(setInventoryOutput());
        break;

      default:
        break;
    }
    yield put(
      setInventoryOutput({
        file_name: action.payload.fileName,
        items: JSON.parse(JSON.stringify(response)),
      })
    );

    yield put(
      setMessage({
        message: "Inventory output data received",
        variant: "info",
      })
    );
    yield put(setIndicator(false));
  } catch (e) {
    yield put(
      setMessage({
        message: "Inventory data retrieval failed",
        variant: "danger",
      })
    );
    yield put(setIndicator(false));
    console.log(e);
  }
}
