import { call, put } from "redux-saga/effects";
import { setSettings } from "../../slices/SettingsSlice";
import { requestGeneral } from "../requests/index";
import { setMessage } from "../../slices/MessageSlice";
import { setIndicator } from "redux/slices/IndicatorSlice.js";
import { setColumns } from "redux/slices/ColumnsSlice";
import { setColumn } from "redux/slices/ColumnsSlice";

export function* handleGetColumns(action) {
  //console.log("handleGetColumns");
  try {
    yield put(setIndicator(true));
    const response = yield call(requestGeneral, {
      url: action.payload.url,
      action: action.payload.action,
    });

    const { data } = response;

    if (data.status === "success") {
      data.data.forEach(d=>{
        d.column_title = d.column_title === null || d.column_title === "" ? d.dataField:d.column_title;
        d.hidden = d.hidden === 0 ? 'N': 'Y';
        d.is_retrieved = d.is_retrieved === 0 ? 'N': 'Y';
        d.sequence = d.sequence * 1;
      //d.current_customer_id = d.current_customer_id * 1;
      //d.id = d.id *1;

      })
      yield put(setColumns({columns:data.data}));
      yield put(
        setMessage({
          message: "Columns data received",
          variant: "success",
        })
      );
    } else {
      console.log("no success");
      yield put(
        setMessage({
          message: data.message + " error number:" + data.error,
          variant: "danger",
        })
      );
    }
    yield put(setIndicator(false));
  } catch (error) {
    yield put(setIndicator(false));
    yield put(
      setMessage({
        message: "Columns data retrieval failed",
        variant: "warning",
      })
    );
  }
}

export function* handleUpdateColumn(action) {
  console.log("handleUpdateColumn");
  console.log(action);
  yield put(setIndicator(true));
  try {
    yield put(
      setMessage({
        message: "Updating settings...",
        variant: "info",
      })
    );

    const response = yield call(requestGeneral, {
      url: action.payload.url,
      ...action.payload,
    });

    console.log(response);
    const { data } = response;

    if (data.status === "success") {
      ///data.data.forEach(d=>{
        //d.column_title = d.column_title === null || d.column_title === "" ? d.dataField:d.column_title;
        //d.hidden = d.hidden === 0 ? 'N': 'Y';
      //})
      //data.data.hidden = data.data.hidden.hidden === 0 ? 'N': 'Y';
      //data.data.column_title = data.data.column_title === null || data.data.column_title === "" ? data.data.dataField:data.data.column_title;
      //console.log(data.data);
      //yield put(setColumn(data.data));
       yield put(
        setMessage({
          message: "Column data has been updated",
          variant: "info"
        })
      ); 
    } else {
       yield put(
        setMessage({
          message: data.message + " error number:" + data.error +" "+ data,
          variant: "danger"
        })
      ); 
    }
    yield put(setIndicator(false));
  } catch (error) {
    yield put(setIndicator(false));
    console.log("error", error);
     yield put(
      setMessage({
        message: "Column data update data failed",
        variant: "warning"
      })
    ); 
  }
}
