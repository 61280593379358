import { createSlice } from "@reduxjs/toolkit";


const columnsSlice = createSlice({
  name: "columns",
  initialState: {columns:[]},
  reducers: {
    getColumns(state, action) {
      //console.log("getSettings",action);
    },
    setColumns(state, action) {
      //console.log("action", action);

      return { ...state,...action.payload };
    },
    setColumn(state, action) {
      
      const columnData = action.payload;
      const columns = [
        ...state.columns.filter((d) => {
          if (d.id === columnData.id) {
            return false;
          } else {
            return true;
          }
        }),
        columnData,
      ]; 

      return { ...state, columns: columns };
    },
    updateColumn() {},
  },
});

export const { getColumns, setColumns, setColumn, updateColumn } = columnsSlice.actions;

export default columnsSlice.reducer;
